import palette from "../theme/palette";

let endPoint = 'http://grpr-da-erwin-dq-pro.westeurope.cloudapp.azure.com/api';
const environment = process.env.REACT_APP_ENVIRONMENT || 'development';
const environmentType = process.env.REACT_APP_ENVIRONMENT_TYPE;
let socketEndpoint = 'http://grpr-da-erwin-dq-pro.westeurope.cloudapp.azure.com';
switch (environment) {
    case 'QA':
        endPoint = 'https://dqlabs-qadock.qaserverix.co/api';
        socketEndpoint = 'https://dqlabs-qadock.qaserverix.co';
        break;
    case 'DEVBOX':
        endPoint = 'http://192.154.241.74/api';
        socketEndpoint = 'http://192.154.241.74';
        break;
    case 'DEMO':
        endPoint = 'https://demo.dqlabs.cloud/api';
        socketEndpoint = 'https://demo.dqlabs.cloud';
        break;
    case 'CLOUD':
        endPoint = 'https://cloud.dqlabs.ai/api';
        socketEndpoint = 'https://cloud.dqlabs.ai';
        break;
    case 'SPOKANE':
        endPoint = 'http://52.126.189.121/api';
        socketEndpoint = 'http://52.126.189.121';
        break;
    case 'POC':
        endPoint = 'http://34.232.13.22/api';
        socketEndpoint = 'http://34.232.13.22';
        break;
    case 'WM':
        endPoint = 'http://10.163.132.112/api';
        socketEndpoint = 'http://10.163.132.112';
        break;
    case 'PROD':
        endPoint = 'http://dqlabs-dock.qaserverix.co:8002/api';
        socketEndpoint = 'http://dqlabs-dock.qaserverix.co:8002';
        break;
    default:
        if (environmentType) {
            endPoint = 'http://grpr-da-erwin-dq-pro.westeurope.cloudapp.azure.com/api';
            socketEndpoint = 'http://grpr-da-erwin-dq-pro.westeurope.cloudapp.azure.com';
        }
        break;
}
export const appConstants = {
    API_BASE_URL: endPoint,
    SSO_URL: 'https://saml-dqlabs-qadock.qaserverix.co/api/sso/',
    SOCKET_URL: socketEndpoint,
    END_POINTS: {
        authenticate: {
            login: 'login',
            loginldap: 'loginldap',
            getUser: 'authenticate/getuser',
            forgotPassword: 'authenticate/forgotpassword',
            verifyResetToken: 'authenticate/verifyresetpasswordtoken',
            resetPassword: 'authenticate/resetpassword',
            getOrganizationUser: 'authenticate/getorganizationuser',
            userActivate: 'authenticate/useractivate',
            getProfile: 'authenticate/getprofile',
            updateProfile: 'authenticate/updateprofile',
            getUsers: 'authenticate/getusers',
            inviteUser: 'authenticate/inviteuser',
            deleteUser: 'authenticate/deleteuser'
        },
        catalog: {
            getAllDataSource: 'catalog/getallsource',
            getDataSourceByID: 'catalog/getsource',
            createSource: 'catalog/createsource',
            updateSource: 'catalog/updatesource',
            updateDataSourceIcon: 'catalog/updatedatasourceicon',
            deleteSource: 'catalog/deletesource',
            getAuditLog: 'catalog/getauditlog',
            getdatasets: 'catalog/getdatasets',
            getattributes: 'catalog/getattributes',
            createAuditLog: 'catalog/createauditlog',
            getdatasetDetail: 'catalog/getdatasetdetail',
            updatedatasetDetail: 'catalog/updatedataset',
            updateTableauItem: 'catalog/updateTableauItem',
            updateDataSetIcon: 'catalog/updatedataseticon',
            sourceShare: 'catalog/createsourceshare',
            getSourceShare: 'catalog/getsourceshare',
            inviteUser: 'catalog/invitesourceuser',
            getPreviewData: 'catalog/getpreviewdata',
            getDatasetInfo: 'catalog/getdatasetinfo',
            deleteDataset: 'catalog/deletedataset',
            getProperties: 'catalog/getproperties',
            getFavorites: 'catalog/getfavorite',
            favorites: 'catalog/favorite',
            getDqScore: 'catalog/getdqscore',
            getDatasetShare: 'catalog/getdatasetshare',
            createDatasetShare: 'catalog/createdatasetshare',
            loadDataset: 'catalog/loaddataset',
            updateProperties: 'catalog/updateproperties',
            getDatasetRules: 'catalog/getdatasetrules',
            updateDatasetRules: 'catalog/updatedatasetrules',
            getCurationRules: 'catalog/getcurationrules',
            getReferences: 'catalog/getreferences',
            updateAttributes: 'catalog/updateattributes',
            getTypes: 'catalog/gettypes',
            userSharing: 'catalog/usersharing',
            filterInput: 'catalog/filter',
            getViews: 'catalog/getviews',
            updateViews: 'catalog/updateviews',
            deleteDatasourceShare: 'catalog/deletesourceshare',
            deleteDatasetShare: 'catalog/deletedatasetshare',
            loadConnectionTypes: 'catalog/loadconnectiontypes',
            connectionType: 'catalog/connectiontype',
            moduleConnectionType: 'catalog/moduleconnectiontype',
            getAllDataSet: 'catalog/getalldataset',
            getAllAttributes: 'catalog/getallattributes',
            updateListProperties: 'catalog/updatelistproperties',
            updateDatasetListRules: 'catalog/updatedatasetlistrules',
            getDatasetNames: 'catalog/datasetnames',
            getUserAttributes: 'catalog/getuserattributes',
            getOrganizationSources: 'catalog/getorganizationsources',
            createDomain: 'catalog/createdomain',
            getDomains: 'catalog/getdomains',
            getAttributes: 'catalog/getattributes',
            getAllDomains: 'catalog/getalldomains',
            getDomainShare: 'catalog/getdomainshare',
            getOrganizationDatasets: 'catalog/getorganizationdatasets',
            getDatasetEnrichments: 'catalog/getalldatasetenrichment',
            createDatasetEnrichment: 'catalog/createdatasetenrichment',
            updateDatasetEnrichment: 'catalog/updatedatasetenrichment',
            deleteDatasetEnrichment: 'catalog/deletedatasetenrichment',
            getDatasetAttributes: 'catalog/getdatasetattributes',
            importProfileReport: 'catalog/importprofilereport',
            importCurateReport: 'catalog/importcuratereport',
            exportProfileReport: 'catalog/exportprofilereport',
            exportCurateReport: 'catalog/exportcuratereport',
            getFieldPropertyByLevel: 'catalog/getfieldpropertybylevel',
            createFieldProperty: 'catalog/createfieldproperty',
            getSourceNames: 'catalog/getsourcenames',
            getSourceDatasets: 'catalog/getsourcedatasets',
            getDriftCustomRule: 'catalog/getdriftcustomrules',
            checkLicense: 'catalog/checklicense',
            getSourceRating: 'catalog/getsourcerating',
            getUserRating: 'catalog/getuserrating',
            getOrganizationDatasetList: 'catalog/getorganizationdatasetlist',
            getOrganizationAttributeList: 'catalog/getorganizationattributelist',
            linkTerm: 'catalog/linkterm',
            getDatasourceCatalog: 'catalog/getdatasourcecatalog',
            getDatasetCatalog: 'catalog/getdatasetcatalog',
            getAttributesCatalog: 'catalog/getattributescatalog',
            saveDuplicateColumn: 'catalog/saveduplicatecolumn',
            getDuplicateColumn: 'catalog/getDuplicateColumn',
            getRecentQueries: 'catalog/getrecentqueries',
            getDDLQuery: 'catalog/getddlquery',
            getDatasourceCatalogTables: 'catalog/getdatasourcecatalogtables',
            getDatasetCatalogTables: 'catalog/getdatasetcatalogtables',
            getAllMonitoringDetails: 'catalog/getallmonitoringdetails',
            getDatasourceMetadataLoad: 'catalog/getdatasourcemetadataload',
            getDatasetMetadataLoad: 'catalog/getdatasetmetadataload',
            exportPropertiesReport: 'catalog/exportpropertiesreport',
            getAttributesMetadata: 'catalog/getattributesmetadata',
            getCatalogListData: 'catalog/get_catalog_list_data',
            getPropertiesList: 'catalog/getdatasetattributeslist',
            getAttributePropertiesList: 'catalog/getattributepropertieslist',
            getAttributeProperties: 'catalog/getfilterattributedata',
            getAttributeRules: 'catalog/getattributerules',
            runCatalog: 'catalog/runcatalog',
            updateDatasetQuery: 'catalog/updatedatasetquery',
            importCatalogMetadata: 'catalog/importcatalogmetadata',
            getOrganizationDatasources: 'catalog/getorganizationdatasources',
            getWorkbookItems: 'catalog/getworkbookitems',
            getWorkbookDatasource: 'catalog/getworkbookdatasource',
            deleteWorkbookItems: 'catalog/deleteworkbookitems',
            getWorkbookInfo: 'catalog/getworkbookinfo',
            getAllAttributeStats: 'catalog/getallattributestats',
            getDatasetListByDatasource: 'catalog/getdatasetlistbydatasource',
            getAttributeListByDatasource: 'catalog/getattributelistbydatasource',
            getExportRulesValidity: 'catalog/getexportrulesvalidity',
            getDriftAttributes: 'catalog/getdriftattributes',
            updateCertified: 'catalog/updatecertified',
            getCertified: 'catalog/getcertified',
            getAttributeRating: 'catalog/getattributerating',
            addusersearchactivity: 'catalog/addusersearchactivity',
            adduserfilteractivity: 'catalog/adduserfilteractivity'
        },
        schedule: {
            runProfile: 'schedule/runprofile',
            getProfileAnalysis: 'schedule/getprofileanalysis',
            getDatasetSchedule: 'schedule/getdatasetschedule',
            getDatasourceSchedule: 'schedule/getdatasourceschedule',
            createSchedule: 'schedule/createschedule',
            createDatasetSchedule: 'schedule/createDatasourceSchedule',
            updateSchedule: 'schedule/updateschedule',
            getDatasetExecutionLogs: 'schedule/getdatasetexecutionlogs',
            getDatasourceExecutionLogs: 'schedule/getdatasourceexecutionlogs',
            getCurateAnalysis: 'schedule/getcurateanalysis',
            getTrending: 'schedule/gettrending',
            restartSchedule: 'schedule/restartschedule',
            profileEnable: 'schedule/profilenenable',
            getProfileRules: 'schedule/getprofilerules',
            killJob: 'schedule/kill',
            getSourceDataQuality: 'schedule/getsourcedataquality',
            getSourceTimeline: 'schedule/getsourcetimeline',
            getSourceSensitivity: 'schedule/getsourcesensitivity',
            learning: 'schedule/datasetlearning',
            deleteLearning: 'schedule/deletelearning',
            getMostView: 'schedule/getmostview',
            getUserAnalysis: 'schedule/getuseranalysis',
            getDqScoreAnalysis: 'schedule/getdqscoreanalysis',
            getRelationShipAnalysis: 'schedule/getrelationshipanalysis',
            getOrganizationTimeline: 'schedule/getorganizationtimeline',
            getOrganizationDqscoreDistribution: 'schedule/getorganizationdqscoredistribution',
            getSensitivityAnalysis: 'schedule/getsensitivityanalysis',
            getConversationAnalysis: 'schedule/getconversationanalysis',
            getOrganizationMetrics: 'schedule/getorganizationmetrics',
            getOrganizationMetricAnalysis: 'schedule/getorganizationmetricanalysis',
            getOrganizationGovernanceAnalysis: 'schedule/getorganizationgovernanceanalysis',
            getNotificationAnalysis: 'schedule/getnotificationmetricanalysis',
            getConversationMetricAnalysis: 'schedule/getconversationmetricanalysis',
            getDatasourceMetrics: 'schedule/getdatasourcemetrics',
            getDomainScore: 'schedule/getdomaindqscore',
            getDomainAsset: 'schedule/getdomainasset',
            getOverAllScore: 'schedule/getoverallscore',
            previewInvalidData: 'schedule/previewinvaliddata',
            exportDataInvalidRecords: 'schedule/exportdatainvalidrecords',
            getDriftRules: 'schedule/getdriftrules',
            getDriftTrending: 'schedule/getdrifttrending',
            getDriftAttribute: 'schedule/getdriftattribute',
            driftCountStatistical: 'schedule/driftcountstatistical',
            driftDescriptiveStatistical: 'schedule/driftdescriptivestatistical',
            driftLengthtSatistical: 'schedule/driftlengthtatistical',
            driftPatternStatistical: 'schedule/driftpatternstatistical',
            driftExtremeSatistical: 'schedule/driftextremestatistical',
            driftRuleAccept: 'schedule/driftruleaccept',
            driftRuleDashboard: 'schedule/getdriftdashboard',
            driftAttributeStepChart: 'schedule/getdriftattributestepcharts',
            driftDatasetDashboard: 'schedule/getdriftdatasetdashboard',
            driftDatasetStepChart: 'schedule/getdriftdatasetstepchart',
            updateattributealerts: 'schedule/updateattributealerts',
            updatedatasetalerts: 'schedule/updatedatasetalerts',
            getDatasetCorrelation: 'schedule/getdatasetcorrelation',
            getAttributePropertyRules: 'schedule/getattributepropertyrules',
            updateAttributePropertyRules: 'schedule/updateattributepropertyrules',
            getDatasetLevelProfileData: 'schedule/getdatasetprofile',
            addMonitoringDetails: 'schedule/addmonitoring',
            getdatasetlevelrules: 'schedule/getdatasetlevelrules',
            getMonitoringAlerts: 'schedule/getmonitoringalerts',
            getMonitoringStatistical: 'schedule/getmonitoringstatistical',
            muteMonitoring: 'schedule/mutemonitoring',
            deleteMonitoring: 'schedule/deletemonitoring',
            ruleOffMonitoring: 'schedule/ruleoffmonitoring',
            getAttributeStatistics: 'catalog/getattributesstatistical',
            getInvalidQueries: 'schedule/getscaninvalidqueries',
            exportRules: 'schedule/downloadexportrules',
            getExecutionCurrentJobDetail: 'schedule/getcurrentjobdetail',
            getDriftBehaviour: 'schedule/getloadbheviourdata',
            removeDataSourceSchedule: 'schedule/removedatasourceschedule',
            removeSchedule: 'schedule/removeschedule',
            getDatasetAttributes: 'schedule/getdatasetattributes',
            getAttributeRules: 'schedule/getattributecustomrules',
            getRecipientUsers: 'schedule/recipientusers',
            createUpdateReport: 'schedule/createupdatereport',
            getReport: 'schedule/getreport',
            getReportById: 'schedule/getreportbyid',
            deleteReportById: 'schedule/deletereport',
            editReportStatus: 'schedule/editreportstatus',
            createCustomReportSchedule: 'schedule/createcustomreportschedule',
            getCustomReportScheduleByReportId: 'schedule/getreportschedulebyreportid',
            deleteCustomReportScheduleByReportId: 'schedule/deletereportschedulebyreportid',
            updateCustomReportScheduleById: 'schedule/updatereportschedulebyid',
            getExportMetricsSetting: 'schedule/getexportmetricssetting',
            saveExportMetricsSetting: 'schedule/saveexportmetricssetting',
            checkExportMetricsSettingName: 'schedule/checkexportmetricssettingname',
            removeExportMetricsSetting: 'schedule/removeexportmetricssetting',
            getExportAttribute: 'schedule/getexportallattribute',
            jobstatuschart: 'schedule/jobstatuschart',
            getDatasourceList: 'schedule/getdatasourcelist',
            getDatasetList: 'schedule/getdatasetlist'
        },
        connectors: {
            connectFiles: 'catalog/connect_files',
            getConnectedFiles: 'catalog/getconnectedfiles',
            connectDatasource: 'catalog/connectdatasource',
            connectDataset: 'catalog/connectdataset',
            getConnectedDatasets: 'catalog/getconnecteddatasets',
            getRestapiConnectedDatasets: 'catalog/get_restapi_connected_datasets',
            connectRestApi: 'catalog/connectrestapi',
            connectRestApiDataset: 'catalog/connectdatasetrestapi',
            uploadfiles: 'catalog/uploadfile',
            getSchema: 'catalog/getschema',
            updateDatasourceProperties: 'catalog/updateDatasourceProperties',
            getConnectionViews: 'catalog/getconnectionviewcount',
            connectTableau: 'catalog/connecttableau',
            getLimitedDatasets: 'catalog/getlimiteddatasets'
        },
        setting: {
            getOrganizations: 'setting/getallorganization',
            createOrganization: 'setting/createorganization',
            getOrganizationDetail: 'setting/getorganization',
            getRoles: 'setting/getroles',
            uploadUserProfile: 'setting/updateuserprofile',
            getReferenceLibrary: 'setting/getreferencelibrary',
            getEnrichmentLibrary: 'setting/getEnrichmentLibrary',
            updateReferenceLibrary: 'setting/updatereferencelibrary',
            updateEnrichmentLibrary: 'setting/updateenrichmentlibrary',
            uploadReferenceLibrary: 'setting/uploadreferencelibrary',
            uploadEnrichmentLibrary: 'setting/uploadenrichementLibrary',
            deleteReferenceLibrary: 'setting/deletereferencelibrary',
            deleteEnrichmentLibrary: 'setting/deleteenrichmentlibrary',
            updateOrganization: 'setting/updateorganization',
            getTheme: 'setting/gettheme',
            updateTheme: 'setting/updatetheme',
            deleteOrganization: 'setting/deleteorganization',
            uploadThemeFile: 'setting/uploadthemefile',
            getModuleConfig: 'setting/getmoduleconfig',
            updateModuleConfig: 'setting/updatemoduleconfig',
            getStorage: 'setting/getstorage',
            updateStorage: 'setting/updatestorage',
            getOrganizationConfig: 'setting/getorganizationconfig',
            loadDefaultConfig: 'setting/loaddefaultconfig',
            getProperties: 'setting/getproperties',
            getPropertiesOrganization: 'setting/getpropertiesorganization',
            getDriftProperties: 'setting/getdriftproperties',
            getLicenses: 'setting/getlicenses',
            validateLicense: 'setting/validatelicense',
            updateProperties: 'setting/updateproperties',
            getEnrichments: 'setting/getallenrichment',
            createEnrichment: 'setting/createenrichment',
            getEnrichmentDetail: 'setting/getenrichment',
            updateEnrichment: 'setting/updateenrichment',
            deleteEnrichment: 'setting/deleteenrichment',
            getFields: 'setting/getallfields',
            getFieldsByLevel: 'setting/getfieldsbylevel',
            createField: 'setting/createfield',
            updateField: 'setting/updatefield',
            updateGroup: 'setting/updategroup',
            updateGroupOrder: 'setting/updategrouporder',
            deleteField: 'setting/deletefield',
            exportReport: 'setting/exportreport',
            importReport: 'setting/importreport',
            updateOrganizationNotificationConfig: 'setting/updateorgnaizationnotifcationconfig',
            getOrganizationNotificationConfig: 'setting/getorgnaizationnotifcationconfig',
            generateOrganizationToken: 'setting/generatetoken',
            revokeOrganizationToken: 'setting/revoketoken',
            getOrganizationToken: 'setting/gettoken',
            getVersionDetails: 'setting/getversion',
            getSSODomainDetails: 'setting/getssodomaindetails',
            getDnsDetails: 'setting/dnsdetails',
            createGlossaryList: 'setting/createglossarylist',
            getGlossaryList: 'setting/getglossarylist',
            getGlossaryById: 'setting/getglossarybyid',
            updateGlossaryList: 'setting/updateglossarylist',
            deleteGlossaryList: 'setting/deleteglossarylist',
            linkAssets: 'catalog/linkassets',
            getLinkAssets: 'catalog/getlinkassets',
            deleteLinkAsset: 'catalog/deletelinkasset',
            getGlossaryTerm: 'setting/getglossaryterms',
            getLogging: 'setting/getloggingfiles',
            getCurrentRunningJobs: 'setting/getrunningjobs',
            getCurrentlivyJobs: 'setting/getsessionstatements',
            killstatement: 'setting/killstatement',
            killbatch: 'setting/killbatch',
            killsession: 'setting/killsession',
            getScheduleDatasets: 'setting/getscheduledatasets',
            getCustomTabs: 'setting/getcustomtabs',
            getCustomGroups: 'setting/getCustomGroups',
            getRoleList: 'setting/getrolelist',
            getIsSuperOrganization: 'setting/getissuperorganization',
            getRoleConfiguration: 'setting/getrolesconfiuration',
            createRole: 'setting/createrole',
            editRole: 'setting/editrole',
            deleteRole: 'setting/deleterole',
            getSystemLicenseStatus: 'setting/getsystemlicensestatus',
            getLicenseModules: 'setting/getlicensemodules',
            updateLicenseConfiguration: 'setting/updatelicenseconfiguration',
            updateConnectorConfig: 'setting/updateconnectorconfig',
            getUpdateLicenseConnectionStatus: 'setting/getupdatelicenseconnectionstatus',
            getCustomFields: 'setting/getfieldsbyorglevel',
            getCustomFieldsByOrg: 'setting/getcustomfieldsbyorglevel',
            exportMetadata: 'setting/exportMetadata',
            exportRules: 'setting/exportRules',
            getLicenseInfo: 'setting/getlicenseinformation',
            getExportDQMetrics: 'setting/getexportdqmetrics',
            getSSODetais: 'setting/getssodetails',
            getLDAPDetais: 'setting/getldapdetails',
            updateExportDQMetrics: 'setting/updateexportdqmetrics',
            uploadSSOFile: 'setting/uploadssofile',
            updateSSODomain: 'setting/updatessodomain',
            updateLDAPDomain: 'setting/updateldapdomain',
            updateLDAPDetails: 'setting/updateldapdetails',
            importDatasetRules: 'setting/importdatasetrules',
            exportUsers: 'setting/exportusers',
            updateSSOPrivatekey: 'setting/updateprivatekey',
            importUsers: 'setting/importusers',
            logoutuser: 'setting/logoutuser',
            exportUsersActivity: 'setting/exportusersactivity',
            exportUsersInfoActivity: 'setting/exportusersinfoactivity',
            checkStewardUserStatus: 'setting/checkstewarduserstatus'
        },
        notification: {
            createActionItem: 'notification/createactionitem',
            getUserActionItem: 'notification/getuseractionitem',
            createActionComment: 'notification/createactioncomment',
            updateActionItem: 'notification/updateactionitem',
            getActionItemCount: 'notification/getactionitemcount',
            getDomainActionItemCount: 'notification/getdomainactionitemcount',
            getAllActionItem: 'notification/getallactionitem',
            getActionItemAnalysis: 'notification/getactionitemanalysis',
            getMyActionItem: 'notification/getmyactionitem',
            deleteActionItem: 'notification/deleteactionitem',
            getNotification: 'notification/getnotification',
            getuserbasedactivity: 'notification/getuserbasedactivity',
            updateNotification: 'notification/updatenotification',
            getNotificationMetrics: 'notification/notificationmetrics',
            createConversation: 'notification/createconversation',
            getConversationBySource: 'notification/getconversationbysource',
            getAllConversation: 'notification/getallconversation',
            updateConversation: 'notification/updateconversation',
            getConversationCount: 'notification/getconversationcount',
            readNotification: 'notification/readnotification',
            getNotificationCount: 'notification/getnotificationcount',
            getActionItemDetail: 'notification/getactionitemdetail',
            getNotificationMetricAnalysis: 'notification/getnotificationmetrics',
            getComments: 'notification/getcomments',
            createComment: 'notification/createcomment',
            getCommentCount: 'notification/getcommentcount',
            deleteComment: 'notification/deletecomment',
            updateComment: 'notification/updatecomment',
            getNotificationRules: 'notification/getnotificationrules',
            getDriftReport: 'notification/getdriftreport',
            getDriftRules: 'notification/getdriftrules',
            getGroupedDriftReport: 'notification/getgroupeddriftreport',
            getGroupByAttributes: 'notification/getgroupbyattributes'
        },
        master: {
            createMasterModel: 'semanticmodel/createmodel',
            getAllMasterModel: 'semanticmodel/getallmodel',
            getModel: 'semanticmodel/getmodel',
            getMasterSharingInfo: 'semanticmodel/getmastersharinginfo',
            createModelAttributes: 'semanticmodel/createmodelattributes',
            updateModelAttributes: 'semanticmodel/updatemodelattributes',
            getAttributes: 'semanticmodel/getattributes',
            deleteAttribute: 'semanticmodel/deleteattribute',
            updateMasterModel: 'semanticmodel/updatemastermodel',
            createMasterShare: 'semanticmodel/createmastershare',
            deleteMasterShare: 'semanticmodel/deletemastershare',
            deleteModel: 'semanticmodel/deletemodel',
            searchSemanticModel: 'semanticmodel/search',
            createActionItem: 'notification/createactionitem',
            createMatchCombination: 'semanticmodel/creatematchcombination',
            updateMatchCombination: 'semanticmodel/updatematchcombination',
            deleteMatchCombination: 'semanticmodel/deletematchcombination',
            getAllMatchCombinations: 'semanticmodel/getallcombinations',
            updateMatchCombinationRank: 'semanticmodel/updatematchcombinationrank',
            publishModel: 'semanticmodel/publishsemanticmodel',
            updateAttributesPosition: 'semanticmodel/updatemodelattributesposition',
            getSchedule: 'semanticmodel/getschedule',
            deleteSchedule: 'semanticmodel/deleteschedule',
            createSchedule: 'semanticmodel/createschedule',
            updateSchedule: 'semanticmodel/updateschedule',
            runMasterModelJob: 'semanticmodel/runjob',
            loadGenericTable: 'semanticmodel/loadgenerictable',
            generatePersonID: 'semanticmodel/generatepersionid',
            getMappings: 'semanticmodel/getmappings',
            getExecutionLogs: 'semanticmodel/getexecutionlogs',
            killMasterJob: 'semanticmodel/killjob',
            restartMasterJobSchedule: 'semanticmodel/restartschedule',
            getMasterFieldTypes: 'semanticmodel/getmasterfieldtypes',
            getGlossaryFields: 'semanticmodel/getglossaryfields',
            createModelAttributeFields: 'semanticmodel/createmodelattributefields',
            updateMasterModelStatus: 'semanticmodel/update_master_model_status'
        },
        domain: {
            getDomainDetail: 'catalog/getdomaindetail',
            updateDomainDetail: 'catalog/updatedomaindetail',
            updateDomain: 'catalog/updatedomain',
            getDomainRules: 'catalog/getdomainrules',
            getDomainMetrics: 'schedule/getdomainmetrics',
            getDomainMetricAnalysis: 'schedule/getdomainmetricanalysis',
            getDomainTimeline: 'schedule/getdomaintimeline',
            getDomainDimensions: 'schedule/getdomaindimensions',
            getDomainRuleProperty: 'catalog/getdomainruleproperty',
            updateDomainRuleProperty: 'catalog/updatedomainruleproperty',
            removeAttributeRule: 'catalog/removeattributerule',
            getOrganizationDomain: 'catalog/getorganizationdomain',
            getDomainDatasource: 'catalog/getdomaindatasource',
            getDomainDataset: 'catalog/getdomaindataset',
            updateRuleWeightage: 'catalog/updateruleweightage',
            deleteDomain: 'catalog/deletedomain',
            getDomainSemanticRules: 'catalog/getdomainsemanticrules',
            updateSemanticField: 'catalog/updatesemanticfield',
            uploadDomainImage: 'setting/uploaddomainimage',
            getdomaindatasources: 'setting/getdomaindatasource',
            getdomaindatasets: 'setting/getdomaindataset',
            getdomainattributes: 'setting/getdomainattribute',
            getdomainrules: 'setting/getdomainrules',
            searchdomain: 'setting/searchdomain',
            getdomainmonitoring: 'setting/getdomainmonitoring'
        },
        dataPreparation: {
            createDataPreparation: 'datapreparation/createdatapreparation',
            getAllDataPreparation: 'datapreparation/getalldatapreparation',
            getDataPreparation: 'datapreparation/getdatapreparation',
            updateDataPreparation: 'datapreparation/updatedatapreparation',
            deleteDataPreparation: 'datapreparation/deletedatapreparation',
            getDatasetAttributes: 'datapreparation/getdatasetattributes',
            previewTansformation: 'datapreparation/previewtansformation',
            applyTansformation: 'datapreparation/applytransformation',
            getConfiguration: 'datapreparation/getconfiguration',
            updateConfiguration: 'datapreparation/updateconfiguration',
            createConfiguration: 'datapreparation/createconfiguration',
            pushTransformedDataset: 'datapreparation/pushtransformeddataset',
            deleteConfiguration: 'datapreparation/deleteconfiguration',
            getTransformations: 'datapreparation/gettransform',
            deleteTransform: 'datapreparation/deletetransform'
        },
        lineage: {
            getmantaflowviewerurl: 'lineage/getmantaflowviewerurl',
            // getmantaflowgraphurl: 'lineage/getLineageGraph',
            getmantaflowgraphurl: 'lineage/get_lineage_graph',
            // getlineageentryurl: 'lineage/getLineageEntry',
            getlineageentryurl: 'lineage/get_lineage_id',
            getSourceCode: 'lineage/getSourceCode',
            getTableauLineage: 'lineage/get_lineage_by_workbook'
        },
        dashboard: {
            getAllDashboards: 'dashboard/getalldashboards',
            createDashboard: 'dashboard/createdashboard',
            updateDashboard: 'dashboard/updatedashboard',
            deleteDashboard: 'dashboard/deletedashboard'
        },
        metrics: {
            getTotalCount: 'metrics/gettotalcount',
            getDatasourceList: 'metrics/getdatasourcelist',
            getDatasetList: 'metrics/getdatasetlist',
            getAttributeList: 'metrics/getattributelist',
            getProfileRules: 'metrics/getprofilerules',
            getDqscoreRules: 'metrics/getdqscorerules',
            getMetrics: 'metrics/getmetrics',
            getPreviewDataByCategory: 'metrics/getpreviewdatabycategory',
            getExportDataByCategory: 'metrics/exportdatabycategory'
        },
        semanticModel: {
            createModel: 'semanticmodel/createsemanticmodel',
            getAllModels: 'semanticmodel/getallsemanticmodel',
            getModelById: 'semanticmodel/getsemanticmodel',
            getAttributes: 'semanticmodel/getsemanticmodelattributes',
            createAttribute: 'semanticmodel/createsemanticmodelattributes',
            updateAttributes: 'semanticmodel/updatesemanticmodelattributes',
            deleteAttributes: 'semanticmodel/deletesemanticmodelattribute',
            updateModel: 'semanticmodel/updatesemanticmodel',
            importModel: 'semanticmodel/importmodel',
            createSemanticModelShare: 'semanticmodel/createsemanticmodelshare',
            deleteSemanticModelShare: 'semanticmodel/deletesemanticmodelshare',
            getSemanticModelSharingInfo: 'semanticmodel/getsemanticmodelsharinginfo',
            exportModel: 'semanticmodel/exportmodel',
            deleteModel: 'semanticmodel/deletesemanticmodel',
            getSemanticModelGlossary: 'semanticmodel/getsemanticmodelglossary',
            createSemanticGlossary: 'semanticmodel/createsemanticglossary',
            getGlossaryDetail: 'semanticmodel/getglossarydetail',
            getAssetFields: 'schedule/getassetfields',
            getSemanticModelDefinition: 'semanticmodel/getsemanticmodeldefinition',
            linkAssets: 'schedule/linkassetfileds',
            unlinkAssetField: 'schedule/unlinkassetfield',
            getAllEnrichment: 'semanticmodel/getallenrichment',
            getBreadCrumbs: 'semanticmodel/getdomainbreadcrums',
            deleteSemanticModelAttributeRule: 'semanticmodel/deletesemanticmodelattributerule',
            getAllDefinitionTags: 'semanticmodel/getsemanticmodeldefinitiontags'
        },
        search: {
            dashboard: 'catalog/search/dashboard',
            track: 'catalog/search/track',
            searchBy: 'catalog/search',
            getAllTags: 'catalog/getalltags'
        }
    },
    fileConnectorTableHeaders: ['File Name', 'File Type', 'File Size', 'Modified', 'Actions'],
    fileConnectorTableHeadersObj: [
        { id: 'name', sort: true, label: 'File Name' },
        { id: 'fileType', sort: false, label: 'File Type' },
        { id: 'size', sort: true, label: 'File Size' },
        { id: 'modifiedDate', sort: true, label: 'Modified' },
        { id: 'connection_type', sort: false, label: 'Run now' },
        { id: 'include_organization_domain_score', sort: false, label: 'Include DQScore' },
        { id: 'actions', sort: false, label: 'Actions' }
    ],
    s3ConnectorTableHeaders: ['File Name', 'Group By', 'File Type', 'File Size', 'Modified', 'Actions'],
    s3ConnectorTableHeadersObj: [
        { id: 'name', sort: true, label: 'File Name' },
        { id: 'groupBy', sort: false, label: 'Group By', isPullOnly: true },
        { id: 'fileType', sort: false, label: 'File Type' },
        { id: 'size', sort: true, label: 'File Size' },
        { id: 'modified_date', sort: true, label: 'Modified' },
        { id: 'connection_type', sort: false, label: 'Run now' },
        { id: 'include_organization_domain_score', sort: false, label: 'Include DQScore' },
        { id: 'actions', sort: false, label: 'Actions' }
    ],
    s3DeltaConnectorTableHeadersObj: [
        { id: 'name', sort: true, label: 'File Name' },
        // { id: 'partition_columns', sort: false, label: 'Partition Columns' },
        { id: 'groupBy', sort: false, label: 'Group By', isPullOnly: true },
        { id: 'fileType', sort: false, label: 'File Type' },
        { id: 'modified_date', sort: true, label: 'Modified' },
        { id: 'connection_type', sort: false, label: 'Run now' },
        { id: 'include_organization_domain_score', sort: false, label: 'Include DQScore' },
        { id: 'actions', sort: false, label: 'Actions' }
    ],
    ftpConnectorTableHeaders: ['File Size', 'Modified', 'Actions'],
    ftpConnectorTableHeadersObj: [
        { id: 'size', sort: true, label: 'File Size' },
        { id: 'modified_date', sort: true, label: 'Modified' },
        { id: 'include_organization_domain_score', sort: false, label: 'Include DQScore' },
        { id: 'actions', sort: false, label: 'Actions' }
    ],
    hadoopConnectorTableHeaders: ['File Size', 'Modified', 'Actions'],
    hadoopConnectorTableHeadersObj: [
        { id: 'size', sort: true, label: 'File Size' },
        { id: 'modified_date', sort: true, label: 'Modified' },
        { id: 'connection_type', sort: false, label: 'Run now' },
        { id: 'include_organization_domain_score', sort: false, label: 'Include DQScore' },
        { id: 'actions', sort: false, label: 'Actions' }
    ],
    sqlConnectorTableHeaders: ['Table Name', 'Schema', 'Columns Count', 'Rows Count', 'Total Size', 'Load Type', 'Fingerprint', 'Actions'],
    sqlConnectorTableHeadersObj: [
        { id: 'table_name', sort: true, label: 'Name' },
        { id: 'schema', sort: true, label: 'Schema' },
        { id: 'table_type', sort: true, label: 'Table' },
        { id: 'columns_count', sort: true, label: 'Columns' },
        { id: 'rows_count', sort: true, label: 'Rows' },
        { id: 'size', sort: true, label: 'Size' },
        { id: 'load_type', sort: false, label: 'Load' },
        { id: 'fingerprint', sort: false, label: 'Fingerprint' },
        { id: 'connection_type', sort: false, label: 'Run now' },
        { id: 'include_organization_domain_score', sort: false, label: 'Include DQScore' },
        { id: 'actions', sort: false, label: 'Actions' }
    ],
    sqlConnectorAttributesTableHeaders: ['Attribute Name', 'Business Name', 'Description', 'Datatype'],
    mysqlConnectorTableHeaders: ['Schema', 'Columns Count', 'Rows Count', 'Total Size', 'Load Type', 'Fingerprint', 'Actions'],
    mysqlConnectorTableHeadersObj: [
        { id: 'schema', sort: true, label: 'Schema' },
        { id: 'columns_count', sort: true, label: 'Columns' },
        { id: 'rows_count', sort: true, label: 'Rows' },
        { id: 'size', sort: true, label: 'Size' },
        { id: 'load_type', sort: false, label: 'Load' },
        { id: 'fingerprint', sort: false, label: 'Fingerprint' },
        { id: 'connection_type', sort: false, label: 'Run now' },
        { id: 'include_organization_domain_score', sort: false, label: 'Include DQScore' },
        { id: 'actions', sort: false, label: 'Actions' }
    ],
    sftpConnectorTableHeaders: ['File Name', 'Actions'],
    sftpConnectorTableHeadersObj: [
        { id: 'name', sort: true, label: 'File Name' },
        { id: 'include_organization_domain_score', sort: false, label: 'Include DQScore' },
        { id: 'actions', sort: false, label: 'Actions' }
    ],
    mongoDBConnectorTableHeaders: ['Row Count', 'Actions'],
    mongoDBConnectorTableHeadersObj: [
        { id: 'row_count', sort: true, label: 'Row Count' },
        { id: 'connection_type', sort: false, label: 'Run now' },
        { id: 'include_organization_domain_score', sort: false, label: 'Include DQScore' },
        { id: 'actions', sort: false, label: 'Actions' }
    ],
    tableauConnectorTableHeadersObj: [
        { id: 'project', sort: true, label: 'Project' },
        { id: 'connection_type', sort: false, label: 'Run now' },
        { id: 'include_organization_domain_score', sort: false, label: 'Include DQScore' },
        { id: 'actions', sort: false, label: 'Actions' }
    ],
    salesforceConnectorTableHeaders: ['Columns Count', 'Actions'],
    salesforceConnectorTableHeadersObj: [
        { id: 'columns_count', sort: true, label: 'Columns Count' },
        { id: 'connection_type', sort: false, label: 'Run now' },
        { id: 'include_organization_domain_score', sort: false, label: 'Include DQScore' },
        { id: 'actions', sort: false, label: 'Actions' }
    ],
    ORACLEConnectorTableHeaders: ['Columns Count', 'Row Count', 'Load Type', 'Fingerprint', 'Run now', 'Actions'],
    PostgresSqlConnectorTableHeaders: ['Columns Count', 'Row Count', 'Load Type', 'Fingerprint', 'Actions'],
    PostgresSqlConnectorTableHeadersObj: [
        { id: 'columns_count', sort: true, label: 'Columns' },
        { id: 'row_count', sort: true, label: 'Rows' },
        { id: 'load_type', sort: false, label: 'Load' },
        { id: 'fingerprint', sort: false, label: 'Fingerprint' },
        { id: 'connection_type', sort: false, label: 'Run now' },
        { id: 'include_organization_domain_score', sort: false, label: 'Include DQScore' },
        { id: 'actions', sort: false, label: 'Actions' }
    ],
    db2TableHeaders: ['Columns Count', 'Rows Count', 'Total Size', 'Load Type', 'Fingerprint', 'Actions'],
    db2TableHeadersObj: [
        { id: 'schema', sort: true, label: 'schema' },
        { id: 'table_type', sort: true, label: 'Table' },
        { id: 'columns_count', sort: true, label: 'Columns' },
        { id: 'num_rows', sort: true, label: 'rows_count' },
        { id: 'load_type', sort: false, label: 'Load' },
        { id: 'fingerprint', sort: false, label: 'Fingerprint' },
        { id: 'connection_type', sort: false, label: 'Run now' },
        { id: 'include_organization_domain_score', sort: false, label: 'Include DQScore' },
        { id: 'actions', sort: false, label: 'Actions' }
    ],
    SnowFlakeConnectorTableHeaders: ['Columns Count', 'Rows Count', 'Total Size', 'Load Type', 'Fingerprint', 'Actions'],
    SnowFlakeConnectorTableHeadersObj: [
        { id: 'schema', sort: true, label: 'Schema' },
        { id: 'table_type', sort: true, label: 'Table' },
        { id: 'columns_count', sort: true, label: 'Columns' },
        { id: 'num_rows', sort: true, label: 'Rows' },
        { id: 'size', sort: true, label: 'Size' },
        { id: 'load_type', sort: false, label: 'Load' },
        { id: 'fingerprint', sort: false, label: 'Fingerprint' },
        { id: 'connection_type', sort: false, label: 'Run now' },
        { id: 'include_organization_domain_score', sort: false, label: 'Include DQScore' },
        { id: 'actions', sort: false, label: 'Actions' }
    ],
    restapiConnectorTableHeaders: ['API', 'Method', 'Parameter', 'Run now', 'Include DQScore', 'Actions'],
    deltaLakeConnectorTableHeaders: ['Database Name', 'Schema', 'Modified', 'Load Type', 'Fingerprint', 'Actions'],
    deltaLakeConnectorTableHeadersObj: [
        { id: 'database', sort: true, label: 'Database Name' },
        { id: 'schema', sort: true, label: 'Schema' },
        { id: 'created_time', sort: true, label: 'Modified' },
        { id: 'load_type', sort: false, label: 'Load Type' },
        { id: 'fingerprint', sort: false, label: 'Fingerprint' },
        { id: 'connection_type', sort: false, label: 'Run now' },
        { id: 'include_organization_domain_score', sort: false, label: 'Include DQScore' },
        { id: 'actions', sort: false, label: 'Actions' }
    ],
    datasetTabList: ['Overview', 'Info', 'Attributes', 'Properties', 'Profile', 'Monitoring', 'Curate', 'Exportmetrics', 'Execution Logs'],
    datasetDriftTabList: ['Overview', 'Info', 'Attributes', 'Properties', 'Profile', 'Monitoring', 'Drift', 'Curate', 'Exportmetrics', 'Execution Logs'],
    datasetTableauList: ['Overview', 'Items', 'Datasources', 'Lineage', 'Execution Logs'],
    datasourceTabList: ['Overview', 'Info', 'Datasets', 'Metrics', 'Execution Logs', 'Configuration'],
    tableauDatasourceTabList: ['Overview', 'Datasets', 'Execution Logs', 'Configuration'],
    dataSetPropetiesTypesList: ['Property', 'Values', 'Rule', 'Curate', 'Learning'],
    attributeTabHeader: ['Property', 'Values', 'Rule', 'Curate', 'Learning', 'Drift', 'Comments'],
    queryInputTableHeader: ['Dataset', 'Query', 'Load Type', 'Run now', 'Include DQScore', 'Actions'],
    defaultQuerySet: {
        name: 'Untitled Dataset',
        isSelected: true,
        'query_string': '',
        'load_type': '',
        'watermark_column': '',
        'include_organization_domain_score': true
    },
    datasourceTypes: {
        File: 'File',
        MSSQL: 'MSSQL',
        MySQL: 'MySQL',
        RestAPI: 'RestAPI',
        SalesForce: 'Salesforce',
        FTP: 'FTP',
        S3: 'S3',
        DB2: 'DB2',
        Sftp: 'SFTP',
        ORACLE: 'ORACLE',
        PostgreSQL: 'PostgreSQL',
        Snowflake: 'Snowflake',
        Hadoop: 'Hadoop',
        MongoDB: 'MongoDB',
        DeltaLake: 'Delta Lake',
        Tableau: 'Tableau',
        Redshift: 'Redshift',
        AzuredataLake: 'AzuredataLake'
    },
    acceptedFileTypes: [
        {
            type: 'csv',
            description: 'Comma Separated Values',
            delimiter: ','
        }, {
            type: 'xlsx',
            description: 'Microsoft Excel'
        }, {
            type: 'json',
            description: 'JSON'
        }, {
            type: 'xml',
            description: 'XML'
        }, {
            type: 'gz',
            description: 'Gzip Compression'
        }, {
            type: 'delta',
            description: 'Delta Table'
        }
    ],
    permissionLevel: [
        {
            value: 1,
            label: 'Public (Level 1)'
        },
        {
            value: 2,
            label: 'Private (Level 2)'
        },
        {
            value: 3,
            label: 'Restricted (Level 3)'
        }
    ],
    executionLogHeaders: [
        {
            'header': 'Dataset',
            'key': 'dataset_name'
        }, {
            'header': 'Type',
            'key': 'schedule_type'
        }, {
            'header': 'Start Time',
            'key': 'start_time'
        }, {
            'header': 'End Time',
            'key': 'end_time'
        }, {
            'header': 'Duration',
            'key': 'duration'
        }, {
            'header': 'Status',
            'key': 'status'
        }, {
            'header': 'Actions',
            'key': ''
        }
    ],
    masterExecutionLogHeaders: ['Model Name', 'Start Time', 'End Time', 'Duration', 'Type', 'Data Source', 'Dataset', 'Status', 'Actions'],
    scheduleMethods: [
        {
            name: 'Minutes',
            value: 'Minute'
        }, {
            name: 'Hourly',
            value: 'Hour'
        }, {
            name: 'Daily',
            value: 'Day'
        }, {
            name: 'Weekly',
            value: 'Week'
        }, {
            name: 'Monthly',
            value: 'Month'
        }
    ],
    weekDays: [
        {
            name: 'Sun',
            value: 'Sunday'
        }, {
            name: 'Mon',
            value: 'Monday'
        }, {
            name: 'Tue',
            value: 'Tuesday'
        }, {
            name: 'Wed',
            value: 'Wednesday'
        }, {
            name: 'Thu',
            value: 'Thursday'
        }, {
            name: 'Fri',
            value: 'Friday'
        }, {
            name: 'Sat',
            value: 'Saturday'
        }
    ],
    TimeZones: [
        {
            name: 'US/Eastern'
        },
        {
            name: 'US/Mountain'
        },
        {
            name: 'US/Pacific'
        },
        {
            name: 'US/Central'
        }
    ],
    charts: {
        length: {
            id: 'id_length',
            name: 'Length Statistics',
            type: 'Length'
        },
        pattern: {
            id: 'id_pattern',
            name: 'Pattern',
            type: 'Pattern'
        },
        soundex: {
            id: 'id_soundex',
            name: 'Soundex Frequency',
            type: 'Soundex Frequency'
        },
        casesensitivity: {
            id: 'id_casesensitivity',
            name: 'Case Sensitivity',
            type: 'Case Sensitivity'
        },
        customrule: {
            id: 'id_customrules',
            name: 'Custom Rule',
            type: 'customrule'
        },
        countstatistics: {
            id: 'id_countstatistics',
            name: 'Count Statistics',
            type: 'countstatistics'
        },
        validvaluescheck: {
            id: 'id_value',
            name: 'Value',
            type: 'Valid Values Check'
        },
        anomaly: {
            id: 'id_anomaly',
            name: 'Anomaly',
            type: 'anomaly'
        },
        trending: {
            id: 'id_trending',
            name: 'Trending'
        },
        histogram: {
            id: 'id_histogram',
            name: 'Histogram',
            type: 'Histogram'
        },
        rules: {
            id: 'id_rules',
            name: 'Rules'
        },
        driftRules: {
            id: 'id_drift_alerts',
            name: 'Alerts'
        },
        basicCuration: {
            id: 'basic_curation',
            name: 'Basic Cleansing'
        },
        advanceCuration: {
            id: 'advance_curation',
            name: 'Advance Cleansing'
        },
        range: {
            id: 'id_extreme',
            name: 'Extreme Values',
            type: 'Range'
        },
        referenceCuration: {
            id: 'reference_curation',
            name: 'Reference Cleansing'
        },
        scatterplot: {
            id: 'id_anomaly',
            name: 'Scatter With Marginal Plot',
            type: 'anomaly'
        },
        normaldistribution: {
            id: 'id_anomaly',
            name: 'Normal Distribution',
            type: 'anomaly'
        },
        boxplot: {
            id: 'id_anomaly',
            name: 'BoxPlot',
            type: 'anomaly'
        },
        kmeans: {
            id: 'id_anomaly',
            name: 'KMeans',
            type: 'anomaly'
        },
        dataqualityChart: {
            id: 'id_dataquality',
            name: 'Data Quality',
            type: 'quality',
            colors: ["#FF9F40", "#4BC0C0", "#FFCD56", "#97E688", "#A3CE9A", "#36A2EB", "#9966FF", "#96DEDE", "#ED94FB", "#695599", "#93548A", "#A66443", "#7D7D7D", "#B96060"]
        },
        sensitivityChart: {
            id: 'id_sensitivity',
            name: 'Sensitivity',
            type: 'Sensitivity'
        },
        timelineChart: {
            id: 'id_timeline',
            name: 'Timeline',
            type: 'timeline'
        },
        correlationChart: {
            id: 'id_correlation',
            name: 'Correlation',
            type: 'correlation'
        },
        domainTimeline: {
            id: 'id_domain_timeline',
            name: 'Timeline',
            type: 'domain_timeline'
        },
        domainDimension: {
            id: 'id_domain_dimension',
            name: 'DQ Dimensions',
            type: 'domain_dimension'
        },
        driftCountStatistics: {
            id: 'id_drift_count_statistics',
            name: 'Count Statistics',
            type: 'count_statistics'
        },
        driftDescriptiveStatistics: {
            id: 'id_drift_descriptive_statistics',
            name: 'Descriptive & Quantile Statistics',
            type: 'count_statistics'
        },
        driftLengthStatistics: {
            id: 'id_drift_length_statistics',
            name: 'Length Statistics',
            type: 'length_statistics'
        },
        driftDashBoardStatistics: {
            id: 'id_drift_pie_statistics',
            name: 'DashBoard Statistics',
            type: 'pie_statistics'
        },
        driftExtremeStatistics: {
            id: 'id_drift_extreme_statistics',
            name: 'Extreme Statistics',
            type: 'extreme_statistics'
        }
    },
    histogramBins: ['10 bins', '25 bins', '50 bins', '100 bins'],
    rangeFilterOptions: ['Top 10', 'Bottom 10'],
    soundexRangeFilterOptions: ['High Frequency', 'Low Frequency'],
    rulesHeader: ["Rule", "Description", "Accepted Count", "Unaccepted Count", "Total Count", "Accepted %", "Actions"],
    driftRulesHeader: ["Rule", "Profile Time", "3 Day %", "7 Day %", "15 Day %", "30 Day %", "60 Day %", "3 Day Zscore", "7 Day Zscore", "15 Day Zscore", "30 Day Zscore", "60 Day Zscore", "Description", "Status", "Actions"],
    sourceTypes: ["quickbase", "restapi", "hdfs", "teradata", "postgres", "salesforce", "file", "s3", "sftp", "ftp", "oracle", "sql"],
    permissionTypes: [{ name: "Owned" }, { name: "Shared" }, { name: "Not Shared" }],
    QueryFilterTypes: ['Valid', 'Invalid'],
    curationHeaders: [{ name: 'Rule Name', value: 'rule_name' }, { name: 'Actual Value', value: 'input_value' }, { name: 'Curate Value', value: 'curated_value' }, { name: 'Changed Records', value: 'changed_records' }, { name: 'Total Records', value: 'total_records' }, { name: 'Impact Percentage', value: 'impact_percentage' }],
    curationAdvanceHeaders: [{ name: 'Rule Name', value: 'rule_name' }, { name: 'Actual Value', value: 'input_value' }, { name: 'Curate Value', value: 'curated_value' }, { name: 'Changed Records', value: 'changed_records' }, { name: 'Total Records', value: 'total_records' }, { name: 'Impact Percentage', value: 'impact_percentage' }, { name: 'Actions', value: 'action' }],
    settingStewardHeaders: ['Profile', 'Organization', 'System', 'Configurations', 'Libraries', 'Theme', 'Enrichment', 'Logging', 'RunningJobs', 'LivyJobs', 'ScheduleDataset'],
    organizationHeaders: ['name', 'email', 'phone', 'address', 'country', 'status', 'actions'],
    organizationTabs: ['General', 'Users', 'System', 'Configurations', 'Libraries', 'Roles', 'Theme', 'Enrichment', 'Logging', 'Reports'],
    organizationAdminTabs: ['Profile', 'Users', 'System', 'Configurations', 'Libraries', 'Roles', 'Theme', 'Enrichment', 'Logging', 'Reports'],
    enrichmentHeaders: ['Name', 'Endpoint', 'Authorization', 'Url Parameters', 'Query Parameters', 'Response Parameters', 'Actions'],
    inboundStorageMethods: ['Store & Profile', 'Profile & Store', 'Profile'],
    previewCounts: ['20', '50', '100', '1000'],
    quadrantList: [{ name: '2 Quadrant', value: 2 }, { name: '3 Quadrant', value: 3 }, { name: '4 Quadrant', value: 4 }, { name: '5 Quadrant', value: 5 }],
    quadrantScoreList: {
        '2': [
            {
                min: 0,
                max: 50,
                color: palette.chartColors.score25
            },
            {
                min: 51,
                max: 100,
                color: palette.chartColors.score100
            }
        ],
        '3': [
            {
                min: 0,
                max: 33,
                color: palette.chartColors.score25
            },
            {
                min: 34,
                max: 66,
                color: palette.chartColors.score50
            },
            {
                min: 67,
                max: 100,
                color: palette.chartColors.score100
            }
        ],
        '4': [
            {
                min: 0,
                max: 25,
                color: palette.chartColors.score25
            },
            {
                min: 26,
                max: 50,
                color: palette.chartColors.score50
            },
            {
                min: 51,
                max: 75,
                color: palette.chartColors.scoreMedium
            },
            {
                min: 76,
                max: 100,
                color: palette.chartColors.score100
            }
        ],
        '5': [
            {
                min: 0,
                max: 20,
                color: palette.chartColors.score25
            },
            {
                min: 21,
                max: 40,
                color: palette.chartColors.score50
            },
            {
                min: 41,
                max: 60,
                color: palette.chartColors.scoreMedium
            },
            {
                min: 61,
                max: 80,
                color: palette.chartColors.score75
            },
            {
                min: 81,
                max: 100,
                color: palette.chartColors.score100
            }
        ]
    },
    driftCounts: ['3', '7', '15', '30', '60'],
    inboundConnectors: ['Disk', 'Hadoop'],
    outboundConnectors: ['Hadoop'],
    SalesforceTypes: ['Production', 'Sandbox'],
    sqlAuthTypes: ['Windows Authentication', 'SQL Server Authentication'],
    redShiftAuthTypes: ['Username/Password', 'SecretManager'],
    redShiftDeltaTypes: ['No', 'Yes'],
    tableauAuthTypes: ['Username and Password', 'Personal Access Token'],
    restAuthTypes: ['Rest Api', 'Swagger Api'],
    loadType: ['Full Load', 'Incremental Load'],
    connectionType: [{ name: 'Yes', value: 'now' }, { name: 'No', value: 'later' }],
    uiRelatedLoadType: ['No', 'Yes'],
    fileLoadType: ['File', 'Folder', "Folder with all files"],
    encryptionTypes: ['No Encryption', 'TLS/SSL Explicit Encryption'],
    lookupLineage: ['Yes', 'No'],
    lineageModule: {
        BO: 'BO',
        B: 'B',
        F: 'F',
        paddingConstants: {
            nodePaddingX: 400,
            nodeItemPadding: 30,
            nodePaddingCorrection: 150
        }
    },
    themeFonts: [
        {
            isDefault: false,
            fontName: 'Montserrat',
            url: 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200&display=swap'
        }, {
            isDefault: false,
            fontName: 'AvenirNextRegular',
            url: ''
        }, {
            isDefault: true,
            fontName: 'Roboto',
            url: ''
        }, {
            isDefault: false,
            fontName: 'Poppins',
            url: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap'
        }, {
            isDefault: false,
            fontName: 'Raleway',
            url: 'https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap'
        }, {
            isDefault: false,
            fontName: 'Open Sans',
            url: 'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap'
        }, {
            isDefault: false,
            fontName: 'Lato',
            url: 'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap'
        }, {
            isDefault: false,
            fontName: 'Slabo',
            url: 'https://fonts.googleapis.com/css2?family=Slabo+13px&display=swap'
        }, {
            isDefault: false,
            fontName: 'Oswald',
            url: 'https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600&display=swap'
        }, {
            isDefault: false,
            fontName: 'Source Sans Pro',
            url: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap'
        }, {
            isDefault: false,
            fontName: 'PT Sans',
            url: 'https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap'
        }, {
            isDefault: false,
            fontName: 'Lora',
            url: 'https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600&display=swap'
        }, {
            isDefault: false,
            fontName: 'Noto Sans',
            url: 'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap'
        }, {
            isDefault: false,
            fontName: 'Nunito Sans',
            url: 'https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap'
        }, {
            isDefault: false,
            fontName: 'Work Sans',
            url: 'https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap'
        },
        {
            isDefault: true,
            fontName: 'Nunito',
            url: 'https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800&display=swap'
        }


    ],
    filterTypes: ['By Datasource', 'By Dataset', 'By Attribute', "By Domain"],
    catalogTypes: ["Datasource", "Dataset", "Attribute"],
    priority: ["High", "Medium", "Low"],
    status: ["new", "open", "resolved"],
    masterTabs: ["Overview", "Define", "Group", "Search", "Assets", "ExecutionLogs"],
    modelTabs: ["Fields"],
    masterGroupTableHeader: [
        {
            title: 'Rank',
            key: 'rank',
            isSortable: true
        },
        {
            title: 'Match Combinations',
            key: 'attributes',
            isSortable: false
        }, {
            title: 'Match Prefix',
            key: 'prefix',
            isSortable: true
        }, {
            title: 'Match Level',
            key: 'level',
            isSortable: true
        }, {
            title: 'Actions',
            key: 'actions',
            isSortable: false
        }
    ],
    levels: [{ level: "1", name: "Level 1" }, { level: "2", name: "Level 2" }, { level: "3", name: "Level 3" }],
    domainTabs: ["Summary", "Quality", "Semantics", 'Monitoring'],
    transformationConstants: [
        {
            name: 'CUR_FISC_YEAR',
            value: `${new Date().getFullYear()}`,
            type: 'constant'
        },
        {
            name: 'LAST_FIVE_FISC_YEAR',
            value: `${new Date().getFullYear() - 4},${new Date().getFullYear()}`,
            type: 'constant'
        }, {
            name: 'LAST_TEN_FISC_YEAR',
            value: `${new Date().getFullYear() - 9},${new Date().getFullYear()}`,
            type: 'constant'
        }
    ],
    MAX_SEARCH_PARAMS: 6,
    commonDataTypes: ["Boolean", "Date", "DateTime", "DropDown", "Integer", "Numeric", "Text", "Time"],
    fieldKeys: {
        datasource: 'DataSource',
        dataset: 'DataSet',
        attribute: 'Attribute',
        tabName: 'Details',
        tabIndex1: 1,
        tabIndex2: 7
    },
    glossaryStatus: [{ "name": "Verified", "value": "verified" }, { "name": "Work In Progress", "value": "work in progress" }, { "name": "Issues", "value": "issues" }, { "name": "Deprecated", "value": "deprecated" }],
    sortOptions: [
        { "name": "Name A - Z", "value": "name_asc" },
        { "name": "Name Z - A", "value": "name_desc" },
        { "name": "Number of terms Low to High", "value": "field_asc" },
        { "name": "Number of terms High to Low", "value": "field_desc" },
        { "name": "Number of Categories Low to High", "value": "category_asc" },
        { "name": "Number of Categories High to Low", "value": "category_desc" }
    ],
    semanticTypes: ["list", "fields"],
    fieldTabs: ["Definition", "Assets"],
    catalogFrequentTableHeaders: [
        { id: 'table_name', sort: true, label: 'Table Name' },
        { id: 'table_type', sort: true, label: 'Table Type' },
        { id: 'table_owner', sort: true, label: 'Table Owner' },
        { id: 'usage_count', sort: true, label: 'Usage Count' }
    ],
    catalogFrequentColumnsHeaders: [
        { id: 'attribute_name', sort: true, label: 'Attribute Name' },
        { id: 'datatype', sort: true, label: 'Datatype' },
        { id: 'usage_count', sort: true, label: 'Usage Count' }
    ],
    catalogFrequentRoleHeaders: [
        { id: 'role_name', sort: true, label: 'Role Name' },
        { id: 'role_comment', sort: false, label: 'Comment' },
        { id: 'assigned_to_users_count', sort: true, label: 'Assigned Users Count' },
        { id: 'assigned_to_users', sort: true, label: 'Assigned Users' }
    ],
    catalogSchemaHeaders: [
        { id: 'schema_name', sort: true, label: 'Schema Name' },
        { id: 'schema_comment', sort: false, label: 'Comment' },
        { id: 'roles_count', sort: true, label: 'Assigned Roles Count' },
        { id: 'roles', sort: true, label: 'Assigned Roles' }
    ],
    catalogTableConstraints: [
        { id: 'table_name', sort: true, label: 'Table Name' },
        { id: 'column_name', sort: true, label: 'Attribute Name' },
        { id: 'constraint', sort: true, label: 'Constraint' }
    ],
    pagePermission: ["All", "Except Setting"],
    levelPermission: ["Level 3", "Level 2", "Level 1", "None"],
    customFieldTabs: ['Info', 'Attributes', 'Properties', 'Profile', 'Monitoring', 'Drift', 'Curate', 'Exportmetrics', 'Comments', 'Execution Logs', 'Datasets', 'Metrics', 'Property', 'Values', 'Rule', 'Learning', 'info', 'Details', 'Lineage'],
    driftChartTypes: ["Bar Chart", "Line Chart", "Histogram", "Heat Map"],
    heatMapAxis: ["Datasource", "Dataset", "Attribute", "Drift Rules"],
    notificationTableHeaders: [{ key: "notification_type", value: "Notification" }, { key: "source__name", value: "Datasource" }, { key: "dataset__name", value: "Dataset" }, { key: "attribute__name", value: "Attribute" }, { key: "notification_text_type", value: "Type" }, { key: "created_by__first_name", value: "User" }, { key: "created_date", value: "DateTime" }, { key: "notification_text", value: "Alert" }],
    notificationUserActivityTableHeaders: [{ key: "username", value: "User Name" }, { key: "last_login", value: "Last Logged IN" }, { key: "number_of_audits", value: "Number of Audits" }, { key: "total_login_count", value: "Total Login Count" }, { key: "ave_minutes", value: "Average Session Duration (mins)" }, { key: "min_minutes", value: "Minimum Session Duration (mins)" }, { key: "max_minutes", value: "Maximum Session Duration (mins)" }, { key: "max_minutes", value: "Audit Information" }],
    notificationScheduleTypeFilters: [{ name: "All", value: "All" }, { name: "Schedule - Pull", value: "pull" }, { name: "Schedule - Sense", value: "sense" }, { name: "Schedule - Scan", value: "scan" }, { name: "Schedule - Profile", value: "profile" }, { name: "Schedule - Curate", value: "curate" }, { name: "Schedule - Learning", value: "learning" }],
    notificationAuditTypeFilters: [{ name: "All", value: "All" }, { name: "AuditLog - Create Dataset", value: "create dataset" }, { name: "AuditLog - Delete Dataset", value: "delete dataset" }, { name: "AuditLog - Updated Datasource name", value: "updated datasource name" }, { name: "AuditLog - Updated Tags", value: "updated tags" }, { name: "AuditLog - Updated Description", value: "updated description" }, { name: "AuditLog - Updated Domain", value: "updated domain" }, { name: "AuditLog - Remove Domain", value: "remove domain" }, { name: "AuditLog - Properties Update", value: "properties update" }, { name: "AuditLog - Storage Update", value: "storage update" }, { name: "AuditLog - Dashboard Create", value: "create" }, { name: "AuditLog - Dashboard Update", value: "update" }, { name: "AuditLog - Dashboard Delete", value: "delete" }, { name: "AuditLog - Themes Update", value: "themes update" }, { name: "AuditLog - Reference Add", value: "reference add" }, { name: "AuditLog - Reference Update", value: "reference update" }, { name: "AuditLog - Reference Delete", value: "reference delete" }],
    notificationActionItemTypeFilters: [{ name: "All", value: "All" }, { name: "Actionitem - Create", value: "create" }, { name: "Actionitem - Update", value: "update" }, { name: "Actionitem - Delete", value: "delete" }],
    notificationPermissionTypeFilters: [{ name: "All", value: "All" }, { name: "Permissioning - Request Access", value: "request access" }, { name: "Permissioning - Accept Access", value: "accept access" }, { name: "Permissioning - Decline Access", value: "decline access" }, { name: "Permissioning - Remove Access", value: "remove access" }, { name: "Permissioning - Share", value: "share" }, { name: "Permissioning - Invite User", value: "invite user" }, { name: "Permissioning - Access Remove", value: "Access Remove" }, { name: "Permissioning - Decline Higher Access", value: "Decline Higher Access" }, { name: "Permissioning - Request Higher Access", value: "Request Higher Access" }],
    notificationOverallFilters: [{ name: "All", value: "All" }, { name: "Schedule - Pull", value: "pull" }, { name: "Schedule - Sense", value: "sense" }, { name: "Schedule - Scan", value: "scan" }, { name: "Schedule - Profile", value: "profile" }, { name: "Schedule - Curate", value: "curate" }, { name: "Schedule - Learning", value: "learning" }, { name: "AuditLog - Create Dataset", value: "create dataset" }, { name: "AuditLog - Delete Dataset", value: "delete dataset" }, { name: "AuditLog - Updated Datasource name", value: "updated datasource name" }, { name: "AuditLog - Updated Tags", value: "updated tags" }, { name: "AuditLog - Updated Description", value: "updated description" }, { name: "AuditLog - Updated Domain", value: "updated domain" }, { name: "AuditLog - Remove Domain", value: "remove domain" }, { name: "AuditLog - Properties Update", value: "properties update" }, { name: "AuditLog - Storage Update", value: "storage update" }, { name: "AuditLog - Dashboard Create", value: "create" }, { name: "AuditLog - Dashboard Update", value: "update" }, { name: "AuditLog - Dashboard Delete", value: "delete" }, { name: "AuditLog - Themes Update", value: "themes update" }, { name: "AuditLog - Reference Add", value: "reference add" }, { name: "AuditLog - Reference Update", value: "reference update" }, { name: "AuditLog - Reference Delete", value: "reference delete" }, { name: "Actionitem - Create", value: "create" }, { name: "Permissioning - Request Access", value: "request access" }, { name: "Permissioning - Accept Access", value: "accept access" }, { name: "Permissioning - Decline Access", value: "decline access" }, { name: "Permissioning - Remove Access", value: "remove_access" }, { name: "Permissioning - Share", value: "share" }, { name: "Permissioning - Invite User", value: "invite user" }, { name: "Permissioning - Access Remove", value: "Access Remove" }],
    heatMapRange: [{ name: "Today", value: 1 }, { name: "Last 3 Days", value: 3 }, { name: "Last 7 Days", value: 7 }, { name: "Last 15 Days", value: 15 }, { name: "Last 30 Days", value: 30 }, { name: "Last 45 Days", value: 45 }],
    environment: environmentType,
    fieldTableHeader: [
        { id: 'name', sort: true, label: 'Name' },
        { id: 'data_type', sort: true, label: 'Data Type' },
        { id: 'dropdown_options', sort: false, label: 'Options' },
        { id: 'description', sort: false, label: 'Description' },
        { id: 'level', sort: true, label: 'Level' },
        { id: 'display_order', sort: true, label: 'Order' },
        { id: 'tab_name', sort: true, label: 'Tab' },
        { id: 'group_name', sort: true, label: 'Group' },
        { id: 'actions', sort: true, label: 'Actions' }
    ],
    referenceTableHeader: [
        { id: 'name', sort: true, label: 'Reference Name' },
        { id: 'type', sort: true, label: 'Reference Type' },
        { id: 'path', sort: true, label: 'Config' },
        { id: 'actions', sort: false, label: 'Actions' }
    ],
    executionLogStatusFilter: [
        "All",
        "Pending",
        "Started",
        "Completed",
        "Failed",
        "Killed"
    ],
    driftReportFilter: [{ label: "All", value: "all" }, { label: "Last 3 days", value: "3" }, { label: "Last 7 days", value: "7" }, { label: "Last 30 days", value: "30" }, { label: "Today", value: "1" }],
    executionJobTypes: [{ name: 'Pull/Scan', value: 'pull/scan' }, { name: 'Profile', value: 'profile' }, { name: 'Curate', value: 'curate' }, { name: 'Learning', value: 'learning' }],
    emailReportType: [{ name: 'Invalid Record Email Notifications', value: 1 }, { name: 'Data Source Level Email Notification', value: 2 }],
    monitoringTypes: [{ name: 'Duplicate', value: 'Duplicate' }, { name: 'Source to Target Comparison', value: 'Source to Target Comparison' }, { name: 'Source to Target Comparison - Custom Rules', value: 'Source to Target Comparison - Custom Rules' }],
    activeStatus: [{ name: 'Active', value: true }, { name: 'In Active', value: false }],
    reportListTableHeader: [
        { id: 'report_name', sort: true, label: 'Name' },
        { id: 'description', sort: true, label: 'Description' },
        { id: 'email_report_type', sort: true, label: 'Email Report Type' },
        { id: 'datasource', sort: true, label: 'Datasource' },
        { id: 'dataset', sort: true, label: 'Dataset' },
        { id: 'last_run', sort: true, label: 'Last Run' },
        { id: 'is_active', sort: true, label: 'Status' },
        { id: 'actions', sort: false, label: 'Actions' }
    ]
};