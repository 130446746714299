import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, withStyles, Typography, Button, MenuItem, AccordionSummary, Accordion, AccordionDetails, Switch, TextField, FormControlLabel, FormControl, RadioGroup, Radio } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classnames from 'classnames';
import TextBox from '../TextBox/TextBox.jsx';
import IntegerInput from '../TextBox/NumberInput.jsx';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import { getProperties, updateProperties } from '../../actions/settingActions';
import Loader from '../Loaders/Loader.jsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { appConstants } from '../../constants/appConstants.js';
import palette from '../../theme/palette.js';
import { getQuadrantError } from '../../helpers/appHelpers.js';

export const ColorDropIcon = (props) => {
    return (
        <svg className="ColorDropIcon" xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none" >
            <path fillRule="evenodd" clipRule="evenodd" d="M8.85589 22C3.98915 22 5.88428e-05 18.0906 5.88428e-05 13.224C5.88428e-05 8.5169 5.26551 3.96945 8.29753 0.299246C8.61671 -0.0996633 9.09531 -0.0996633 9.33462 0.299246C12.3663 3.96913 17.6321 8.51651 17.6321 13.224C17.6321 18.0907 13.7227 22 8.85608 22H8.85589Z" fill={props.dropColor} />
        </svg>
    );
};

ColorDropIcon.propTypes = {
    dropColor: PropTypes.string
};

const Properties = (props) => {
    const { classes, organizationId } = props;
    const [properties, setProperties] = useState({
        previewCount: appConstants.previewCounts[0],
        driftCount: appConstants.driftCounts[0],
        dq_score_threshold: {
            quadrant: appConstants.quadrantList[0].value,
            scores: appConstants.quadrantScoreList[appConstants.quadrantList[0].value]
        },
        no_of_running_job: '',
        senseProperties: {},
        profileRules: {
            count: [],
            statistical: [],
            basic: [],
            outlier: [],
            sampling: [],
            text: [],
            custom: ['CustomRule'],
            fieldtype: ['fieldtype'],
            datatype: ['datatype'],
            lookup: ['lookup'],
            invalidrecords: ['invalidrecords']
        },
        driftProperties: {},
        profileConfig: {},
        driftRules: {
            driftAlertFlag: true,
            driftAlertCount: null,
            driftAlertTime: null,
            driftAlertType: null
        },
        curationRulesConfig: {
            basic: true
        },
        semanticLearning: {
            isEnabled: false,
            learningType: 'attribute',
            velocity: "10%"
        },
        domainProperties: {
            create_new_domain: true,
            domain_propagation: true,
            group_by_datasource: true,
            domaininvalidrecords: []
        }
    });
    const [isLoading, setLoading] = useState(false);
    const [isChanged, setChanged] = useState(false);
    const [hasQuadrantError, setHasQuadrantError] = useState(false);
    const [selectedPanel, setSelectedPanel] = useState('Sense');
    const [selectedProfileRulesPanel, setSelectedProfileRulesPanel] = useState('');
    const [selectedDomainRulesPanel, setSelectedDomainRulesPanel] = useState('');
    const config = useSelector(({ setting }) => setting.config);
    const dispatch = useDispatch();
    const getUserProperties = useCallback(() => {
        dispatch(getProperties(organizationId)).then((response) => {
            if (response) {
                setProperties({ ...properties, ...response });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, organizationId]);

    useEffect(() => {
        getUserProperties();
    }, [getUserProperties]);

    const onSubmit = () => {
        setLoading(true);
        dispatch(updateProperties(organizationId, properties)).then(() => {
            setChanged(false);
            setLoading(false);
        });
    };

    const onChange = (name, value) => {
        if (name === 'dq_score_threshold') {
            if (value.name === 'quadrant') {
                properties[name][value.name] = value.value;
                properties[name].scores = appConstants.quadrantScoreList[`${value.value}`];
            } else {
                properties[name].scores[value.index][value.name] = value.value;
            }
            setHasQuadrantError(getQuadrantError(properties?.dq_score_threshold?.scores || [], properties[name].scores[value.index], value.index) !== null || getQuadrantError(properties?.dq_score_threshold?.scores || [], null, null) !== null);
        } else {
            properties[name] = value;
        }
        setProperties({ ...properties });
        setChanged(true);
    };

    const domainRuleChange = (event, propertyName, key, type) => {
        const outlierRule = ['dataset', 'attribute'];

        if (!(propertyName in properties)) {
            properties[propertyName] = {};
        }

        if (type === "parent") {
            if (event.target.checked) {
                switch (event.target.name) {
                    case 'domaininvalidrecords': properties[propertyName][event.target.name] = outlierRule; break;
                    default: break;
                }
            }
            else {
                properties[propertyName][event.target.name] = [];
            }
        } else {
            if (!(key in properties[propertyName])) {
                properties[propertyName][key] = [];
            }
            if (event.target.checked) {
                properties[propertyName][key].push(event.target.name);
            } else {
                const index = properties[propertyName][key].indexOf(event.target.name);
                if (index !== -1) {
                    properties[propertyName][key].splice(index, 1);
                }
            }
        }
        setProperties({ ...properties });
        setChanged(true);
    };

    const profileRuleChange = (event, propertyName, key, type) => {
        const countRule = ['null_count', 'blank', 'digits', 'distinct_count', 'duplicate', 'unique_count', 'characters', 'white_space', 'special_characters', 'zero_values'];
        const statisticalRule = ['descriptive', 'quantile'];
        const basicRule = ['Length', 'Pattern', 'Range', 'ValidValuesCheck'];
        const outlierRule = ['NormalDistribution', 'KMeansCluster', 'BoxPlot', 'ScatterPlot'];
        const samplingRule = ['HistogramRule'];
        const textRule = ['SoundexFrequency', 'CaseSensitive'];
        const custom = ['CustomRule'];
        const fieldtype = ['fieldtype'];
        const datatype = ['datatype'];
        const lookup = ['lookup'];
        const invalidrecords = ['invalidrecords'];

        if (!(propertyName in properties)) {
            properties[propertyName] = {};
        }

        if (type === "parent") {
            if (event.target.checked) {
                switch (event.target.name) {
                    case 'count': properties[propertyName][event.target.name] = countRule; break;
                    case 'statistical': properties[propertyName][event.target.name] = statisticalRule; break;
                    case 'basic': properties[propertyName][event.target.name] = basicRule; break;
                    case 'outlier': properties[propertyName][event.target.name] = outlierRule; break;
                    case 'sampling': properties[propertyName][event.target.name] = samplingRule; break;
                    case 'text': properties[propertyName][event.target.name] = textRule; break;
                    case 'custom': properties[propertyName][event.target.name] = custom; break;
                    case 'fieldtype': properties[propertyName][event.target.name] = fieldtype; break;
                    case 'datatype': properties[propertyName][event.target.name] = datatype; break;
                    case 'lookup': properties[propertyName][event.target.name] = lookup; break;
                    case 'invalidrecords': properties[propertyName][event.target.name] = invalidrecords; break;
                    default: break;
                }
            }
            else {
                properties[propertyName][event.target.name] = [];
            }
        } else {
            if (!(key in properties[propertyName])) {
                properties[propertyName][key] = [];
            }
            if (event.target.checked) {
                properties[propertyName][key].push(event.target.name);
            } else {
                const index = properties[propertyName][key].indexOf(event.target.name);
                if (index !== -1) {
                    properties[propertyName][key].splice(index, 1);
                }
            }
        }

        // Remove infinite & missing count values in existing configurations if any
        if (key.toLowerCase() === 'count') {
            const countRules = properties[propertyName][key];
            const keysToRemove = ['infinite', 'missing'];
            for (const ruleKey of keysToRemove) {
                const index = countRules.indexOf(ruleKey);
                if (index > -1) {
                    countRules.splice(index, 1);
                }
            }
            properties[propertyName][key] = countRules;
        }

        setProperties({ ...properties });
        setChanged(true);
    };

    const handleChange = (event, propertyName, key) => {
        const countRule = ['null_count', 'blank', 'digits', 'distinct_count', 'infinite', 'duplicate', 'missing', 'unique_count', 'characters', 'white_space', 'special_characters', 'zero_values'];
        const statisticalRule = ['descriptive', 'quantile'];
        const basicRule = ['Length', 'Pattern', 'Range', 'ValidValuesCheck'];
        const outlierRule = ['NormalDistribution', 'KMeansCluster', 'BoxPlot', 'ScatterPlot'];
        const samplingRule = ['HistogramRule'];
        const textRule = ['SoundexFrequency', 'CaseSensitive'];

        if (!(propertyName in properties)) {
            properties[propertyName] = {};
        }
        if (event.target.name === "percentage_value" || event.target.name === "driftAlertCount" || event.target.name === "driftAlertTime" || event.target.name === "semanticLearning") {
            if (key === 'velocity') {
                const velocityRegex = /^[0-9%]+$/;
                if (velocityRegex.test(event.target.value) || event.target.value === '') {
                    properties[propertyName][key] = event.target.value;
                }
            } else {
                properties[propertyName][key] = event.target.value;
            }

        } else if (propertyName === "profileRules") {
            if (key === "") {
                if (properties[propertyName][event.target.value].length === 0 || event.target.value === "custom" || event.target.value === "fieldtype" || event.target.value === "lookup" || event.target.value === "invalidrecords") {
                    switch (event.target.value) {
                        case 'count': properties[propertyName][event.target.value] = countRule; break;
                        case 'statistical': properties[propertyName][event.target.value] = statisticalRule; break;
                        case 'basic': properties[propertyName][event.target.value] = basicRule; break;
                        case 'outlier': properties[propertyName][event.target.value] = outlierRule; break;
                        case 'sampling': properties[propertyName][event.target.value] = samplingRule; break;
                        case 'text': properties[propertyName][event.target.value] = textRule; break;
                        case 'custom': properties[propertyName][event.target.value] = !properties[propertyName][event.target.value]; break;
                        case 'fieldtype': properties[propertyName][event.target.value] = !properties[propertyName][event.target.value]; break;
                        case 'datatype': properties[propertyName][event.target.value] = !properties[propertyName][event.target.value]; break;
                        case 'lookup': properties[propertyName][event.target.value] = !properties[propertyName][event.target.value]; break;
                        case 'invalidrecords': properties[propertyName][event.target.value] = !properties[propertyName][event.target.value]; break;
                        default: break;
                    }
                } else {
                    properties[propertyName][event.target.value] = [];
                }
            } else {
                if (properties[propertyName][key].includes(event.target.value)) {
                    const index = properties[propertyName][key].indexOf(event.target.value);
                    if (index > -1) {
                        properties[propertyName][key].splice(index, 1);
                    }
                } else {
                    properties[propertyName][key].push(event.target.value);
                }
            }
        } else {
            properties[propertyName][key] = event.target.checked;
        }

        /*
         * if(key == "isEnabled" && propertie == 'semanticLearning' && event.target.value == false){
         *     properties[propertie]['learningType'] = 'attribute';
         *     properties[propertie]['velocity'] = '10%';
         * }
         */

        setProperties({ ...properties });
        setChanged(true);
    };

    const toggleExpansionPanel = (panel) => (event, isExpanded) => {
        isExpanded = isExpanded ? panel : false;
        setSelectedPanel(isExpanded);
        setSelectedProfileRulesPanel(false);
    };

    const profileRulesPanel = (panel) => (event, isExpanded) => {
        isExpanded = isExpanded ? panel : false;
        setSelectedProfileRulesPanel(isExpanded);
    };

    const domainRulesPanel = (panel) => (event, isExpanded) => {
        isExpanded = isExpanded ? panel : false;
        setSelectedDomainRulesPanel(isExpanded);
    };

    return (
        <ValidatorForm form="My properties" onSubmit={() => onSubmit()}>
            <Grid container spacing={4} className={classes.padding5}>
                <Grid item xs={12}>
                    <Grid container spacing={8}>
                        <Grid item xs={2}>
                            <TextBox
                                label="Preview Count"
                                value={properties.previewCount}
                                name="previewCount"
                                fullWidth
                                select
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                SelectProps={
                                    {
                                        value: properties.previewCount,
                                        onChange: (event) => onChange(event.target.name, event.target.value),
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center"
                                            },
                                            getContentAnchorEl: null
                                        }
                                        // IconComponent: () => DropDownIcon()
                                    }
                                }
                            >
                                {
                                    appConstants.previewCounts.map((previewCount, index) =>
                                        <MenuItem key={`preview_count_${index}`} value={previewCount}>
                                            {previewCount}
                                        </MenuItem>
                                    )
                                }
                            </TextBox>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                value={properties.dataset_percentage ? properties.dataset_percentage : 1}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                name="dataset_percentage"
                                label="Dataset Percentage"
                            />
                        </Grid>
                        {
                            !organizationId &&
                            <Grid item xs={2}>
                                <IntegerInput
                                    value={properties.no_of_running_job ? properties.no_of_running_job : ''}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    name="no_of_running_job"
                                    label="Max Concurrent Jobs"
                                    integerOnly
                                    validators={['minNumber:1']}
                                    errorMessages={['Should Greater than as 0']}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            config.drift &&
                            <Grid item xs={2}>
                                <TextField
                                    value={properties.driftCount ? properties.driftCount : ''}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    name="driftCount"
                                    label="Drift Days Count"
                                />
                            </Grid>
                        }
                        {
                            config.drift &&
                            <Grid item xs={2} className={classes.configSwitches}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={properties && properties.skip_drift_date ? properties.skip_drift_date : false}
                                            onChange={(event) => onChange(event.target.name, event.target.checked)}
                                            name="skip_drift_date"
                                        />
                                    }
                                    label="Skip Drift Date"
                                />
                            </Grid>
                        }
                        <Grid item xs={2} className={classes.configSwitches}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={properties && properties.database_masking ? properties.database_masking : false}
                                        onChange={(event) => onChange(event.target.name, event.target.checked)}
                                        name="database_masking"
                                    />
                                }
                                label="Push Permission"
                            />
                        </Grid>
                        {
                            !organizationId &&
                            <Grid item xs={2}>
                                <TextField
                                    value={properties.host_address ? properties.host_address : ''}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    name="host_address"
                                    label="Host Address"
                                />
                            </Grid>
                        }
                        {
                            !organizationId &&
                            <Grid item xs={2}>
                                <TextField
                                    value={properties.dns_name ? properties.dns_name : ''}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    name="dns_name"
                                    label="DNS Name"
                                />
                            </Grid>
                        }
                        {/* {
                            !organizationId &&
                            <Grid item xs={2}>
                                <TextField
                                    value={properties.ldap_server ? properties.ldap_server : ''}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    name="ldap_server"
                                    label="LDAP Server"
                                />
                            </Grid>
                        }
                        {
                            !organizationId &&
                            <Grid item xs={2}>
                                <TextField
                                    value={properties.ldap_domain_name ? properties.ldap_domain_name : ''}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    name="ldap_domain_name"
                                    label="LDAP Domain Name"
                                />
                            </Grid>
                        } */}
                        {
                            !organizationId &&
                            <Grid item xs={2}>
                                <TextField
                                    value={properties.max_time_limit ? properties.max_time_limit : ''}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    name="max_time_limit"
                                    label="Max Time Limit(Mins)"
                                />
                            </Grid>
                        }
                        {
                            !organizationId &&
                            <Grid item xs={2}>
                                <TextField
                                    // style={{ width: '200px' }}
                                    value={properties.check_job_status ? properties.check_job_status : ''}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    name="check_job_status"
                                    label="Check Job Status(Mins)"
                                />
                            </Grid>
                        }
                        <Grid item xs={4} className={classes.configSwitches}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={properties && properties.include_organization_domain_score ? properties.include_organization_domain_score : false}
                                        onChange={(event) => onChange(event.target.name, event.target.checked)}
                                        name="include_organization_domain_score"
                                    />
                                }
                                label="Include Organisation and Domain score"
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.configSwitches}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={properties && properties.enable_event_based_scheduling ? properties.enable_event_based_scheduling : false}
                                        onChange={(event) => onChange(event.target.name, event.target.checked)}
                                        name="enable_event_based_scheduling"
                                    />
                                }
                                label="Enable Event Based Scheduling"
                            />
                        </Grid>
                        {
                            config && config.export_metrics && 
                            <Grid item xs={4} className={classes.configSwitches}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={properties && properties.enable_custom_export_metrics ? properties.enable_custom_export_metrics : false}
                                            onChange={(event) => onChange(event.target.name, event.target.checked)}
                                            name="enable_custom_export_metrics"
                                        />
                                    }
                                    label="Enable Custom Export Metrics"
                                />
                            </Grid>
                        }
                        
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Accordion expanded={selectedPanel === "Sense"} onChange={toggleExpansionPanel("Sense")} className={classes.storageAcoordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header">
                            <Typography className={classes.heading}>
                                {"Sense"}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container style={{ marginLeft: '15px' }}>
                                <Grid xs={3}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={properties.senseProperties && properties.senseProperties.percentage_change ? properties.senseProperties.percentage_change : false}
                                                onChange={(event) => handleChange(event, "senseProperties", "percentage_change")}
                                                name="Percentage"
                                            />
                                        }
                                        label="Percentage Change"
                                    />
                                    {
                                        properties.senseProperties && properties.senseProperties.percentage_change &&
                                        <TextField
                                            style={{ width: '50px' }}
                                            value={properties.senseProperties && properties.senseProperties.percentage_value ? properties.senseProperties.percentage_value : ''}
                                            onChange={(event) => handleChange(event, "senseProperties", "percentage_value")}
                                            name="percentage_value"
                                        />
                                    }
                                </Grid>

                                <Grid xs={3}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={properties.senseProperties && properties.senseProperties.schema_change ? properties.senseProperties.schema_change : false}
                                                onChange={(event) => handleChange(event, "senseProperties", "schema_change")}
                                                name="schema_change"
                                            />
                                        }
                                        label="Schema Change"
                                    />
                                </Grid>

                                <Grid xs={3}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={properties.senseProperties && properties.senseProperties.fieldtype_change ? properties.senseProperties.fieldtype_change : false}
                                                onChange={(event) => handleChange(event, "senseProperties", "fieldtype_change")}
                                                name="fieldtype_change"

                                            />
                                        }
                                        label="Fieldtype Change"
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={properties && properties.skip_duplicate_count ? properties.skip_duplicate_count : false}
                                                onChange={(event) => onChange(event.target.name, event.target.checked)}
                                                name="skip_duplicate_count"

                                            />
                                        }
                                        label="Duplicate Count"
                                    />
                                </Grid>

                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {
                    config.profile &&

                    <Grid item xs={12}>
                        <Accordion expanded={selectedPanel === "ProfileConfig"} onChange={toggleExpansionPanel("ProfileConfig")} className={classes.storageAcoordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header">
                                <Typography className={classes.heading}>
                                    {"Profile"}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container style={{ marginLeft: '15px' }}>
                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.profileConfig && properties.profileConfig.known_field_types ? properties.profileConfig.known_field_types : false}
                                                    onChange={(event) => handleChange(event, "profileConfig", "known_field_types")}
                                                    name="known_field_types"

                                                />
                                            }
                                            label="Known Field Types"
                                        />
                                    </Grid>
                                    {
                                        config.drift &&
                                        <Grid xs={3}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={properties.profileConfig && properties.profileConfig.drift_enabled ? properties.profileConfig.drift_enabled : false}
                                                        onChange={(event) => handleChange(event, "profileConfig", "drift_enabled")}
                                                        name="drift_enabled"

                                                    />
                                                }
                                                label="Drift Enabled"
                                            />
                                        </Grid>
                                    }
                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.profileConfig && properties.profileConfig.custom_rules_configured ? properties.profileConfig.custom_rules_configured : false}
                                                    onChange={(event) => handleChange(event, "profileConfig", "custom_rules_configured")}
                                                    name="drift_enabled"

                                                />
                                            }
                                            label="Custom Rules Configured"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.profileConfig && properties.profileConfig.known_datatypes ? properties.profileConfig.known_datatypes : false}
                                                    onChange={(event) => handleChange(event, "profileConfig", "known_datatypes")}
                                                    name="known_datatypes"

                                                />
                                            }
                                            label="Known Data Types"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.profileConfig && properties.profileConfig.sense_enabled_scoring ? properties.profileConfig.sense_enabled_scoring : false}
                                                    onChange={(event) => handleChange(event, "profileConfig", "sense_enabled_scoring")}
                                                    name="sense_enabled_scoring"

                                                />
                                            }
                                            label="Sense Enabled Scoring"
                                        />
                                    </Grid>
                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.profileConfig && properties.profileConfig.valid_records_notification ? properties.profileConfig.valid_records_notification : false}
                                                    onChange={(event) => handleChange(event, "profileConfig", "valid_records_notification")}
                                                    name="valid_records_notification"

                                                />
                                            }
                                            label="Valid Records Email Notification"
                                        />
                                    </Grid>
                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.profileConfig && properties.profileConfig.datasource_level_rule ? properties.profileConfig.datasource_level_rule : false}
                                                    onChange={(event) => handleChange(event, "profileConfig", "datasource_level_rule")}
                                                    name="datasource_level_rule"

                                                />
                                            }
                                            label="Enable Data Source Level Rule Configuration"
                                        />
                                    </Grid>
                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.profileConfig && properties.profileConfig.statistical_rule_invalid_records ? properties.profileConfig.statistical_rule_invalid_records : false}
                                                    onChange={(event) => handleChange(event, "profileConfig", "statistical_rule_invalid_records")}
                                                    name="statistical_rule_invalid_records"

                                                />
                                            }
                                            label="Count Statistical Rule in Invalid Records"
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Accordion expanded={selectedPanel === "Profile"} onChange={toggleExpansionPanel("Profile")} className={classes.storageAcoordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header">
                            <Typography className={classes.heading}>
                                {"Rules"}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Accordion expanded={selectedProfileRulesPanel === "Count"} onChange={profileRulesPanel("Count")} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }} className={classes.storageAcoordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header">
                                                <Typography className={classes.heading}>
                                                    <Switch defaultChecked
                                                        checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0}
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={(event) => profileRuleChange(event, "profileRules", "count", "parent")}
                                                        name="count"
                                                        value="Count"
                                                    />
                                                    {"Count"}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container style={{ marginLeft: '30px' }}>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('null_count')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="null_count"
                                                                    value="Null Count"
                                                                />
                                                            }
                                                            label="Null Count"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('blank')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="blank"
                                                                    value="Blank"

                                                                />
                                                            }
                                                            label="Blank"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('digits')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="digits"
                                                                    value="Digits"

                                                                />
                                                            }
                                                            label="Digits"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('distinct_count')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="distinct_count"
                                                                    value="Distinct Count"

                                                                />
                                                            }
                                                            label="Distinct Count"
                                                        />
                                                    </Grid>
                                                    {/* <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('infinite')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="infinite"
                                                                    value="Infinite"
                                                                />
                                                            }
                                                            label="Infinite"
                                                        />
                                                    </Grid> */}
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('duplicate')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="duplicate"
                                                                    value="Duplicate"

                                                                />
                                                            }
                                                            label="Duplicate"
                                                        />
                                                    </Grid>
                                                    {/* <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('missing')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="missing"
                                                                    value="Missing"

                                                                />
                                                            }
                                                            label="Missing"
                                                        />
                                                    </Grid> */}
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('unique_count')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="unique_count"
                                                                    value="Unique Count"

                                                                />
                                                            }
                                                            label="Unique Count"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('characters')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="characters"
                                                                    value="Characters"

                                                                />
                                                            }
                                                            label="Characters"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('white_space')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="white_space"
                                                                    value="White Space"

                                                                />
                                                            }
                                                            label="White Space"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('special_characters')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="special_characters"
                                                                    value="Special Characters"

                                                                />
                                                            }
                                                            label="Special Characters"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('zero_values')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="zero_values"
                                                                    value="Zero Values"

                                                                />
                                                            }
                                                            label="Zero Values"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                }
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Accordion expanded={selectedProfileRulesPanel === "Statistical"} onChange={profileRulesPanel("Statistical")} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }} className={classes.storageAcoordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header">
                                                <Typography className={classes.heading}>
                                                    <Switch defaultChecked
                                                        checked={properties.profileRules && properties.profileRules.statistical && Object.keys(properties.profileRules.statistical).length > 0}
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={(event) => profileRuleChange(event, "profileRules", "statistical", "parent")}
                                                        name="statistical"
                                                        value="Statistical"
                                                    />
                                                    {"Statistical"}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container style={{ marginLeft: '30px' }}>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.statistical && Object.keys(properties.profileRules.statistical).length > 0 && Object.values(properties.profileRules.statistical).includes('descriptive')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "statistical", "child")}
                                                                    name="descriptive"
                                                                    value="Descriptive"
                                                                />
                                                            }
                                                            label="Descriptive"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.statistical && Object.keys(properties.profileRules.statistical).length > 0 && Object.values(properties.profileRules.statistical).includes('quantile')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "statistical", "child")}
                                                                    name="quantile"
                                                                    value="Quantile"
                                                                />
                                                            }
                                                            label="Quantile"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                }
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Accordion expanded={selectedProfileRulesPanel === "Basic"} onChange={profileRulesPanel("Basic")} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }} className={classes.storageAcoordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header">
                                                <Typography className={classes.heading}>
                                                    <Switch defaultChecked
                                                        checked={properties.profileRules && properties.profileRules.basic && Object.keys(properties.profileRules.basic).length > 0}
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={(event) => profileRuleChange(event, "profileRules", "basic", "parent")}
                                                        name="basic"
                                                        value="Basic"
                                                    />
                                                    {"Basic"}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container style={{ marginLeft: '30px' }}>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.basic && Object.keys(properties.profileRules.basic).length > 0 && Object.values(properties.profileRules.basic).includes('Length')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "basic", "child")}
                                                                    name="Length"
                                                                    value="Length"
                                                                />
                                                            }
                                                            label="Length"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.basic && Object.keys(properties.profileRules.basic).length > 0 && Object.values(properties.profileRules.basic).includes('Pattern')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "basic", "child")}
                                                                    name="Pattern"
                                                                    value="Pattern"
                                                                />
                                                            }
                                                            label="Pattern"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.basic && Object.keys(properties.profileRules.basic).length > 0 && Object.values(properties.profileRules.basic).includes('Range')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "basic", "child")}
                                                                    name="Range"
                                                                    value="Range (Extreme Value)"
                                                                />
                                                            }
                                                            label="Range (Extreme Value)"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.basic && Object.keys(properties.profileRules.basic).length > 0 && Object.values(properties.profileRules.basic).includes('ValidValuesCheck')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "basic", "child")}
                                                                    name="ValidValuesCheck"
                                                                    value="Values"
                                                                />
                                                            }
                                                            label="Values"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                }
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Accordion expanded={selectedProfileRulesPanel === "Outlier"} onChange={profileRulesPanel("Outlier")} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }} className={classes.storageAcoordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header">
                                                <Typography className={classes.heading}>
                                                    <Switch
                                                        checked={properties.profileRules && properties.profileRules.outlier && Object.keys(properties.profileRules.outlier).length > 0}
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={(event) => profileRuleChange(event, "profileRules", "outlier", "parent")}
                                                        name="outlier"
                                                        value="Outlier"
                                                    />
                                                    {"Outlier"}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container style={{ marginLeft: '30px' }}>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={properties.profileRules && properties.profileRules.outlier && Object.keys(properties.profileRules.outlier).length > 0 && Object.values(properties.profileRules.outlier).includes('NormalDistribution')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "outlier", "child")}
                                                                    name="NormalDistribution"
                                                                    value="Normal Distribution"
                                                                />
                                                            }
                                                            label="Normal Distribution"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={properties.profileRules && properties.profileRules.outlier && Object.keys(properties.profileRules.outlier).length > 0 && Object.values(properties.profileRules.outlier).includes('KMeansCluster')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "outlier", "child")}
                                                                    name="KMeansCluster"
                                                                    value="K-means"
                                                                />
                                                            }
                                                            label="K-means"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={properties.profileRules && properties.profileRules.outlier && Object.keys(properties.profileRules.outlier).length > 0 && Object.values(properties.profileRules.outlier).includes('BoxPlot')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "outlier", "child")}
                                                                    name="BoxPlot"
                                                                    value="Box Plot"
                                                                />
                                                            }
                                                            label="Box Plot"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={properties.profileRules && properties.profileRules.outlier && Object.keys(properties.profileRules.outlier).length > 0 && Object.values(properties.profileRules.outlier).includes('ScatterPlot')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "outlier", "child")}
                                                                    name="ScatterPlot"
                                                                    value="Scatter Plot"
                                                                />
                                                            }
                                                            label="Scatter Plot"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                }
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Accordion expanded={selectedProfileRulesPanel === "Sampling"} onChange={profileRulesPanel("Sampling")} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }} className={classes.storageAcoordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header">
                                                <Typography className={classes.heading}>
                                                    <Switch
                                                        checked={properties.profileRules && properties.profileRules.sampling && Object.keys(properties.profileRules.sampling).length > 0}
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={(event) => profileRuleChange(event, "profileRules", "sampling", "parent")}
                                                        name="sampling"
                                                        value="Sampling"
                                                    />
                                                    {"Sampling"}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container style={{ marginLeft: '30px' }}>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={properties.profileRules && properties.profileRules.sampling && Object.keys(properties.profileRules.sampling).length > 0 && Object.values(properties.profileRules.sampling).includes('HistogramRule')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "sampling", "child")}
                                                                    name="HistogramRule"
                                                                    value="Histogram"
                                                                />
                                                            }
                                                            label="Histogram"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                }
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Accordion expanded={selectedProfileRulesPanel === "Text"} onChange={profileRulesPanel("Text")} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }} className={classes.storageAcoordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header">
                                                <Typography className={classes.heading}>
                                                    <Switch defaultChecked
                                                        checked={properties.profileRules && properties.profileRules.text && Object.keys(properties.profileRules.text).length > 0}
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={(event) => profileRuleChange(event, "profileRules", "text", "parent")}
                                                        name="text"
                                                        value="Text"
                                                    />
                                                    {"Text"}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container style={{ marginLeft: '30px' }}>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.text && Object.keys(properties.profileRules.text).length > 0 && Object.values(properties.profileRules.text).includes('SoundexFrequency')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "text", "child")}
                                                                    name="SoundexFrequency"
                                                                    value="Soundex"
                                                                />
                                                            }
                                                            label="Soundex"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.text && Object.keys(properties.profileRules.text).length > 0 && Object.values(properties.profileRules.text).includes('CaseSensitive')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "text", "child")}
                                                                    name="CaseSensitive"
                                                                    value="Case Sensitivity"
                                                                />
                                                            }
                                                            label="Case Sensitivity"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                }
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Typography className={classnames(classes.heading, classes.accordianStyles)} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }}>
                                            <Switch defaultChecked
                                                checked={properties.profileRules && properties.profileRules.custom && Object.keys(properties.profileRules.custom).length > 0}
                                                onClick={(event) => event.stopPropagation()}
                                                onChange={(event) => profileRuleChange(event, "profileRules", "custom", "parent")}
                                                name="custom"
                                                value="Custom"
                                            />
                                            {"Custom"}
                                        </Typography>
                                    </Grid>
                                }
                                <Grid xs={4}>
                                    <Typography className={classnames(classes.heading, classes.accordianStyles)} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }}>
                                        <Switch defaultChecked
                                            checked={properties.profileRules && properties.profileRules.fieldtype && Object.keys(properties.profileRules.fieldtype).length > 0}
                                            onClick={(event) => event.stopPropagation()}
                                            onChange={(event) => profileRuleChange(event, "profileRules", "fieldtype", "parent")}
                                            name="fieldtype"
                                            value="fieldtype"
                                        />
                                        {"Field Type Sense"}
                                    </Typography>
                                </Grid>
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Typography className={classnames(classes.heading, classes.accordianStyles)} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }}>
                                            <Switch defaultChecked
                                                checked={properties.profileRules && properties.profileRules.datatype && Object.keys(properties.profileRules.datatype).length > 0}
                                                onClick={(event) => event.stopPropagation()}
                                                onChange={(event) => profileRuleChange(event, "profileRules", "datatype", "parent")}
                                                name="datatype"
                                                value="datatype"
                                            />
                                            {"Data Type Sense"}
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Typography className={classnames(classes.heading, classes.accordianStyles)} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }}>
                                            <Switch defaultChecked
                                                checked={properties.profileRules && properties.profileRules.invalidrecords && Object.keys(properties.profileRules.invalidrecords).length > 0}
                                                onClick={(event) => event.stopPropagation()}
                                                onChange={(event) => profileRuleChange(event, "profileRules", "invalidrecords", "parent")}
                                                name="invalidrecords"
                                                value="invalidrecords"
                                            />
                                            {"Invalid Records Save"}
                                        </Typography>
                                    </Grid>
                                }
                                <Grid xs={4}>
                                    <Typography className={classnames(classes.heading, classes.accordianStyles)} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }}>
                                        <Switch defaultChecked
                                            checked={properties.profileRules && properties.profileRules.lookup && Object.keys(properties.profileRules.lookup).length > 0}
                                            onClick={(event) => event.stopPropagation()}
                                            onChange={(event) => profileRuleChange(event, "profileRules", "lookup", "parent")}
                                            name="lookup"
                                            value="Lookup"
                                        />
                                        {"Lookup"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {
                    config.curate &&
                    <Grid item xs={12}>
                        <Accordion expanded={selectedPanel === "Curation"} onChange={toggleExpansionPanel("Curation")} className={classes.storageAcoordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header">
                                <Typography className={classes.heading}>
                                    {"Curation"}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid justifyContent="space-between" container style={{ marginLeft: '15px' }}>
                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.curationRulesConfig && properties.curationRulesConfig.basic ? properties.curationRulesConfig.basic : false}
                                                    name="Basic"
                                                    onChange={(event) => handleChange(event, "curationRulesConfig", "basic")}
                                                />
                                            }
                                            label="Basic"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.curationRulesConfig && properties.curationRulesConfig.reference ? properties.curationRulesConfig.reference : false}
                                                    onChange={(event) => handleChange(event, "curationRulesConfig", "reference")}
                                                    name="Reference"

                                                />
                                            }
                                            label="Reference"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.curationRulesConfig && properties.curationRulesConfig.advanced ? properties.curationRulesConfig.advanced : false}
                                                    onChange={(event) => handleChange(event, "curationRulesConfig", "advanced")}
                                                    name="Advanced"

                                                />
                                            }
                                            label="Advanced"
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                }
                {
                    config.semantics &&
                    <Grid item xs={12}>
                        <Accordion expanded={selectedPanel === "SemanticLearning"} onChange={toggleExpansionPanel("SemanticLearning")} className={classes.storageAcoordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header">
                                <Typography className={classes.heading}>
                                    {"Semantic Learning"}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid xs={12} className={classes.configSwitches}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.semanticLearning && properties.semanticLearning.isEnabled ? properties.semanticLearning.isEnabled : false}
                                                    onChange={(event) => handleChange(event, "semanticLearning", "isEnabled")}
                                                    name="isEnabled"

                                                />
                                            }
                                            label="Enable Semantic Learning"
                                        />
                                    </Grid>
                                    {
                                        properties.semanticLearning && properties.semanticLearning.isEnabled &&
                                        <Grid xs={12} className={classnames(classes.subModuleSection, classes.marginTop20)}>
                                            <Grid container justify="space-between">
                                                <Grid xs={6}>
                                                    <FormControl component="fieldset">
                                                        <Typography>
                                                            Learning By
                                                        </Typography>
                                                        <RadioGroup row aria-label="semanticLearning" name="semanticLearning" value={properties.semanticLearning && properties.semanticLearning.learningType ? properties.semanticLearning.learningType : 'attribute'} onChange={(event) => handleChange(event, "semanticLearning", "learningType")}>
                                                            <FormControlLabel value="datasource" control={<Radio />} label="Datasource" />
                                                            <FormControlLabel value="dataset" control={<Radio />} label="Dataset" />
                                                            <FormControlLabel value="attribute" control={<Radio />} label="Attribute" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid xs={6}>

                                                    <Typography>
                                                        Velocity (No. Changes or %)
                                                    </Typography>
                                                    <TextBox
                                                        placeholder="ex: 10 or 10%"
                                                        name="semanticLearning"
                                                        id="velocity"
                                                        value={properties.semanticLearning && properties.semanticLearning.velocity ? properties.semanticLearning.velocity : ''}
                                                        onChange={(event) => handleChange(event, "semanticLearning", "velocity")} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                }
                {
                    config.drift &&
                    <Grid item xs={12}>
                        <Accordion expanded={selectedPanel === "Drift"} onChange={toggleExpansionPanel("Drift")} className={classes.storageAcoordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header">
                                <Typography className={classes.heading}>
                                    {"Drift Rules"}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container style={{ marginLeft: '15px' }}>
                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.Correlation ? properties.driftProperties.Correlation : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "Correlation")}
                                                    name="Correlation"
                                                />
                                            }
                                            label="Correlation"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.duplicate_count ? properties.driftProperties.duplicate_count : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "duplicate_count")}
                                                    name="Duplicate Count"
                                                />
                                            }
                                            label="Duplicate Count"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.primary_duplicate_count ? properties.driftProperties.primary_duplicate_count : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "primary_duplicate_count")}
                                                    name="Primary Duplicate Count"
                                                />
                                            }
                                            label="Primary Duplicate Count"
                                        />
                                    </Grid>


                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.row_count ? properties.driftProperties.row_count : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "row_count")}
                                                    name="Row Count"
                                                />
                                            }
                                            label="Row Count"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.multicolumn_duplicate_count ? properties.driftProperties.multicolumn_duplicate_count : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "multicolumn_duplicate_count")}
                                                    name="Multicolumn Duplicate Count"
                                                />
                                            }
                                            label="Multicolumn Duplicate Count"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.addcolumn ? properties.driftProperties.addcolumn : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "addcolumn")}
                                                    name="Add Column"
                                                />
                                            }
                                            label="Add Column"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.removecolumn ? properties.driftProperties.removecolumn : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "removecolumn")}
                                                    name="Remove Column"
                                                />
                                            }
                                            label="Remove Column"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.null_count ? properties.driftProperties.null_count : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "null_count")}
                                                    name="Null Count"
                                                />
                                            }
                                            label="Null Count"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.unique_count ? properties.driftProperties.unique_count : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "unique_count")}
                                                    name="Unique Count"
                                                />
                                            }
                                            label="Unique Count"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.blank ? properties.driftProperties.blank : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "blank")}
                                                    name="Blank"
                                                />
                                            }
                                            label="Blank"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.digits ? properties.driftProperties.digits : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "digits")}
                                                    name="Digits"
                                                />
                                            }
                                            label="Digits"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.special_characters ? properties.driftProperties.special_characters : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "special_characters")}
                                                    name="Special Characters"
                                                />
                                            }
                                            label="Special Characters"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.distinct_count ? properties.driftProperties.distinct_count : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "distinct_count")}
                                                    name="Distinct Count"
                                                />
                                            }
                                            label="Distinct Count"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.infinite ? properties.driftProperties.infinite : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "infinite")}
                                                    name="Infinite"
                                                />
                                            }
                                            label="Infinite"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.duplicate ? properties.driftProperties.duplicate : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "duplicate")}
                                                    name="Duplicate"
                                                />
                                            }
                                            label="Duplicate"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.missing ? properties.driftProperties.missing : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "missing")}
                                                    name="Missing"
                                                />
                                            }
                                            label="Missing"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.characters ? properties.driftProperties.characters : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "characters")}
                                                    name="Characters"
                                                />
                                            }
                                            label="Characters"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.white_space ? properties.driftProperties.white_space : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "white_space")}
                                                    name="White Space"
                                                />
                                            }
                                            label="White Space"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.Length ? properties.driftProperties.Length : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "Length")}
                                                    name="Length"
                                                />
                                            }
                                            label="Length"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.Pattern ? properties.driftProperties.Pattern : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "Pattern")}
                                                    name="Pattern"
                                                />
                                            }
                                            label="Pattern"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.Validvalues ? properties.driftProperties.Validvalues : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "Validvalues")}
                                                    name="Validvalues"
                                                />
                                            }
                                            label="Valid Values"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.Timeseries_Analysis ? properties.driftProperties.Timeseries_Analysis : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "Timeseries_Analysis")}
                                                    name="Timeseries Analysis"
                                                />
                                            }
                                            label="Timeseries Analysis"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.Behaviour_Analysis ? properties.driftProperties.Behaviour_Analysis : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "Behaviour_Analysis")}
                                                    name="Behaviour Analysis"
                                                />
                                            }
                                            label="Behaviour Analysis"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.DataType_Change ? properties.driftProperties.DataType_Change : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "DataType_Change")}
                                                    name="DataType Change"
                                                />
                                            }
                                            label="DataType Change"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.FieldType_Change ? properties.driftProperties.FieldType_Change : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "FieldType_Change")}
                                                    name="FieldType Change"
                                                />
                                            }
                                            label="FieldType Change"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.sum ? properties.driftProperties.sum : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "sum")}
                                                    name="Sum"
                                                />
                                            }
                                            label="Sum"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.q1 ? properties.driftProperties.q1 : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "q1")}
                                                    name="Q1"
                                                />
                                            }
                                            label="Q1"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.q2 ? properties.driftProperties.q2 : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "q2")}
                                                    name="Q2"
                                                />
                                            }
                                            label="Q2"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.q3 ? properties.driftProperties.q3 : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "q3")}
                                                    name="Q3"
                                                />
                                            }
                                            label="Q3"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.mean ? properties.driftProperties.mean : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "mean")}
                                                    name="Mean"
                                                />
                                            }
                                            label="Mean"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.kurtosis ? properties.driftProperties.kurtosis : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "kurtosis")}
                                                    name="Kurtosis"
                                                />
                                            }
                                            label="Kurtosis"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.skewness ? properties.driftProperties.skewness : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "skewness")}
                                                    name="Skewness"
                                                />
                                            }
                                            label="Skewness"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.variance ? properties.driftProperties.variance : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "variance")}
                                                    name="Variance"
                                                />
                                            }
                                            label="Variance"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.standard_deviation ? properties.driftProperties.standard_deviation : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "standard_deviation")}
                                                    name="Standard Deviation"
                                                />
                                            }
                                            label="Standard Deviation"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.coefficient_of_variation ? properties.driftProperties.coefficient_of_variation : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "coefficient_of_variation")}
                                                    name="Coefficient of Variation"
                                                />
                                            }
                                            label="Coefficient of Variation"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.median_absolute_deviation ? properties.driftProperties.median_absolute_deviation : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "median_absolute_deviation")}
                                                    name="Median Absolute Deviation"
                                                />
                                            }
                                            label="Median Absolute Deviation"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.iqr ? properties.driftProperties.iqr : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "iqr")}
                                                    name="IQR"
                                                />
                                            }
                                            label="IQR"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.range ? properties.driftProperties.range : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "range")}
                                                    name="Range"
                                                />
                                            }
                                            label="Range"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.max ? properties.driftProperties.max : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "max")}
                                                    name="Max"
                                                />
                                            }
                                            label="Max"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.min ? properties.driftProperties.min : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "min")}
                                                    name="min"
                                                />
                                            }
                                            label="min"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.median ? properties.driftProperties.median : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "median")}
                                                    name="Median"
                                                />
                                            }
                                            label="Median"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.zero_values ? properties.driftProperties.zero_values : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "zero_values")}
                                                    name="Zero Values"
                                                />
                                            }
                                            label="Zero Values"
                                        />
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.CustomRule ? properties.driftProperties.CustomRule : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "CustomRule")}
                                                    name="Custom Rules"
                                                />
                                            }
                                            label="Custom Rules"
                                        />
                                    </Grid>
                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.driftProperties && properties.driftProperties.incremental_row_count ? properties.driftProperties.incremental_row_count : false}
                                                    onChange={(event) => handleChange(event, "driftProperties", "incremental_row_count")}
                                                    name="incremental_row_count"
                                                />
                                            }
                                            label="Incremental Row Count"
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                }
                {
                    config.domain &&
                    <Grid item xs={12}>
                        <Accordion expanded={selectedPanel === "Domain"} onChange={toggleExpansionPanel("Domain")} className={classes.storageAcoordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header">
                                <Typography className={classes.heading}>
                                    {"Domain"}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container style={{ marginLeft: '15px' }}>
                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.domainProperties && properties.domainProperties.create_new_domain ? properties.domainProperties.create_new_domain : false}
                                                    onChange={(event) => handleChange(event, "domainProperties", "create_new_domain")}
                                                    name="Create New Domain"
                                                />
                                            }
                                            label="Create New Domain"
                                        />
                                    </Grid>
                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.domainProperties && properties.domainProperties.domain_propagation ? properties.domainProperties.domain_propagation : false}
                                                    onChange={(event) => handleChange(event, "domainProperties", "domain_propagation")}
                                                    name="Domain Propagation"
                                                />
                                            }
                                            label="Domain Propagation"
                                        />
                                    </Grid>
                                    <Grid xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={properties.domainProperties && properties.domainProperties.group_by_datasource ? properties.domainProperties.group_by_datasource : false}
                                                    onChange={(event) => handleChange(event, "domainProperties", "group_by_datasource")}
                                                    name="Group By Datasource"
                                                />
                                            }
                                            label="Group By Datasource"
                                        />
                                    </Grid>
                                    <Grid xs={3}>
                                        <Accordion expanded={selectedDomainRulesPanel === "domaininvalidrecords"} onChange={domainRulesPanel("domaininvalidrecords")} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }} className={classes.storageAcoordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header">
                                                <Typography className={classes.heading}>
                                                    <Switch defaultChecked
                                                        checked={properties.domainProperties && properties.domainProperties.domaininvalidrecords && Object.keys(properties.domainProperties.domaininvalidrecords).length > 0}
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={(event) => domainRuleChange(event, "domainProperties", "domaininvalidrecords", "parent")}
                                                        name="domaininvalidrecords"
                                                        value="domaininvalidrecords"
                                                    />
                                                    {"Show Count"}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container style={{ marginLeft: '30px' }}>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.domainProperties && properties.domainProperties.domaininvalidrecords && Object.keys(properties.domainProperties.domaininvalidrecords).length > 0 && Object.values(properties.domainProperties.domaininvalidrecords).includes('dataset')}
                                                                    onChange={(event) => domainRuleChange(event, "domainProperties", "domaininvalidrecords", "child")}
                                                                    name="dataset"
                                                                    value="dataset"
                                                                />
                                                            }
                                                            label="Dataset"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.domainProperties && properties.domainProperties.domaininvalidrecords && Object.keys(properties.domainProperties.domaininvalidrecords).length > 0 && Object.values(properties.domainProperties.domaininvalidrecords).includes('attribute')}
                                                                    onChange={(event) => domainRuleChange(event, "domainProperties", "domaininvalidrecords", "child")}
                                                                    name="attribute"
                                                                    value="attribute"

                                                                />
                                                            }
                                                            label="Attribute"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Accordion expanded={selectedPanel === "Domains"} onChange={toggleExpansionPanel("Domains")} className={classes.storageAcoordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header">
                            <Typography className={classes.heading}>
                                {"DQScore Threshold"}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.displayBlock}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextBox
                                        label="No. of Quadrant"
                                        value={properties?.dq_score_threshold?.quadrant}
                                        name="quadrant"
                                        fullWidth
                                        select
                                        onChange={(event) => onChange('dq_score_threshold', { name: event.target.name, value: event.target.value })}
                                        SelectProps={
                                            {
                                                value: properties?.dq_score_threshold?.quadrant,
                                                onChange: (event) => onChange('dq_score_threshold', { name: event.target.name, value: event.target.value }),
                                                MenuProps: {
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "center"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "center"
                                                    },
                                                    getContentAnchorEl: null
                                                }
                                            }
                                        }
                                    >
                                        {
                                            appConstants.quadrantList.map((quadrant, index) =>
                                                <MenuItem key={`quadrant_${index}`} value={quadrant.value}>
                                                    {quadrant.name}
                                                </MenuItem>
                                            )
                                        }
                                    </TextBox>
                                </Grid>
                                {
                                    properties?.dq_score_threshold?.scores.map(((score, index) => (
                                        <Grid key={index} item xs={3}>
                                            <Grid container className={classes.scoringValuePicker}>
                                                <Grid item className={classes.rangeFields}>
                                                    <IntegerInput
                                                        id="length-min-ntb"
                                                        name={'min'}
                                                        value={score.min}
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                        integerOnly
                                                        onChange={(event) => onChange('dq_score_threshold', { index, name: event.target.name, value: event.target.value ? Number(event.target.value) : event.target.value })}
                                                    />
                                                    {' '}
                                                    <span className="rangeHypen">
                                                        -
                                                    </span>
                                                    <IntegerInput
                                                        id="length-max-ntb"
                                                        name={'max'}
                                                        value={score.max}
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                        integerOnly
                                                        onChange={(event) => onChange('dq_score_threshold', { index, name: event.target.name, value: event.target.value ? Number(event.target.value) : event.target.value })}
                                                    />
                                                    <span className={classes.colorPicker}>
                                                        <ColorDropIcon
                                                            dropColor={score.color || palette.chartColors.trending}
                                                        />
                                                        <input
                                                            className="typeColor"
                                                            name="color"
                                                            type="color"
                                                            value={score.color}
                                                            onChange={(event) => onChange('dq_score_threshold', { index, name: event.target.name, value: event.target.value })}
                                                        />
                                                    </span>
                                                </Grid>
                                            </Grid>
                                            {
                                                hasQuadrantError &&
                                                <Typography style={{ padding: 12, color: '#F44336' }}>
                                                    {getQuadrantError(properties?.dq_score_threshold?.scores || [], score, index)}
                                                </Typography>
                                            }
                                        </Grid>
                                    )))
                                }
                            </Grid>
                            {
                                hasQuadrantError &&
                                <Typography style={{ paddingTop: 24, color: '#F44336' }}>
                                    {getQuadrantError(properties?.dq_score_threshold?.scores || [], null, null)}
                                </Typography>
                            }
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {
                    isChanged &&
                    <Grid item xs={12}>
                        <Button disabled={isLoading || hasQuadrantError} variant="contained" color="primary" type="submit" className={classnames(classes.actionButtons, classes.submitBtn)}>
                            Save
                            {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                        </Button>
                    </Grid>
                }
            </Grid>
        </ValidatorForm >
    );
};

Properties.propTypes = {
    classes: PropTypes.object,
    organizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Properties);